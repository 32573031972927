import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataStore } from "../Store/Context";

function FamilyReunionPage() {

    const { data, lan } = useContext(DataStore);

    const { title, titleImage, titleDescription1, titleDescription2, title2, title2Description1, title2Description2, title2Description3, title2Description4, title2Description5, title2Description6, title2Description7, title2Description8, title2Description9, title2Description10, title2Description11, title2Description12, text, contactTitle, contactPersonEmail, contactPersonTel } = data[0].family[0];
    const { offerText1, offerText2, offerButton, email, phone } = data[0].homePageData[0];

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, []);

    return (
        <div className="container px-3">
            {/* page Title */}
            <div className="row mt-5 mb-5 text-center">
                <h3>{title}</h3>
            </div>

            {/* first section with the image*/}
            <div className="row mb-4">
                <div className="col-lg-6">
                    <p>{titleDescription1}</p>
                </div>
                <div className="col-lg-6">
                    <img src={titleImage} alt="" />
                </div>
            </div>

            {/* second section Text*/}
            <div className="row mb-4">
                <p>{titleDescription2}</p>
            </div>

            {/* third section and description*/}
            <div className="row mb-4">
                <h5>{title2}</h5>
                {lan && lan === "Ar" ? <ul className="px-5">
                    <li>{title2Description1}</li>
                    <li>{title2Description2}</li>
                    <li>{title2Description3}</li>
                    <li>{title2Description4}</li>
                    <li>{title2Description5}</li>
                    <li>{title2Description6}</li>
                    <li>{title2Description7}</li>
                    <li>{title2Description8}</li>
                    <li>{title2Description9}</li>
                    <li>{title2Description10}</li>
                    <li>{title2Description11}</li>
                    <li>{title2Description12}</li>
                </ul> : <ul className="px-5">
                    <li>{title2Description1}</li>
                    <li>{title2Description2}</li>
                    <li>{title2Description3}</li>
                    <li>{title2Description4}</li>
                    <li>{title2Description5}</li>
                    <li>{title2Description6}</li>
                    <li>{title2Description7}</li>
                    <li>{title2Description8}</li>
                    <li>{title2Description9}</li>
                </ul>}
            </div>

            {/* forth section */}
            <div className="row">
                <p>{text}</p>
            </div>

            {/* contact person */}
            <div className="row mt-5 d-flex align-items-center">
                <div className="row mb-1"><h5>{contactTitle}</h5></div>
                {lan && lan === "Ar" ? <div className="col-lg-4 col-sm-12 mb-5" >
                    <h6 className="mb-0"> عن طريق الإيميل: <a className="text-decoration-none" href={`mailto:${contactPersonEmail}`}>{contactPersonEmail}</a></h6>
                    <h6 > عن طريق الهاتف: <a className="text-decoration-none" href={`tel:${contactPersonTel}`}>{contactPersonTel}</a> </h6>
                </div> : <div className="col-lg-4 col-sm-12 mb-5" style={{ direction: "ltr" }}>
                    <p className="mb-0">{email} <a className="text-decoration-none" href={`mailto:${contactPersonEmail}`}>{contactPersonEmail}</a></p>
                    <p >{phone} <a className="text-decoration-none" href={`tel:${contactPersonTel}`}>{contactPersonTel}</a></p>
                </div>}


            </div>

            {/* wisdom */}
            <div className="row text-center mb-5 pt-5">
                <h5>{offerText1}</h5>
                <h5>{offerText2}</h5>
            </div>
            <div className="row text-center mt-5 mb-5 d-flex justify-content-center">
                <div className="col"><Link to="/requestforquotefamily" className="btn btn-primary btn-lg">{offerButton}</Link></div>
            </div>
        </div>
    )
}

export default FamilyReunionPage
