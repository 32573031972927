import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { WebData } from './Store/Context';

ReactDOM.render(
  <React.StrictMode>
    <WebData children={undefined}>
      <Router>
        <App children={undefined} />
      </Router>
    </WebData>
  </React.StrictMode>,
  document.getElementById('root')
);
