import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

function SecondBlog({ data }) {

  let blog = data.map(item => item)[0];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, []);


  return <div className="container px-5 mb-5">
    {/* page Title */}
    <div className="row mt-5 mb-3">
      <h3>{blog.title}</h3>
      <p>{blog.createdAt}</p>
    </div>

    {/* blog Image */}
    <div className="row">
      <div className="col-lg-6">
        <img src={blog.imageTitle} alt="blog_image" className="img-fluid" />
      </div>
      <div className="row">
        <p className="mt-3">{blog.text1}</p>
      </div>
    </div>

    {/* first section */}
    <div className="row mt-3">
      <h5>{blog.title2}</h5>
      <p>{blog.title2Text}</p>
      <ul style={{ listStyle: "none" }}>
        <li >{blog.title2Description1}</li>
        <li>{blog.title2Description2}</li>
        <li>{blog.title2Description3}</li>
        <li>{blog.title2Description4}</li>
      </ul>
      <p>{blog.title2Text2}</p>
    </div>

    {/* second section */}
    <div className="row">
      <h5>{blog.title3}</h5>
      <p>{blog.title3Description}</p>
    </div>

    {/* third section */}
    <div className="row">
      <h5>{blog.title4}</h5>
      <p>{blog.title4Description}</p>
    </div>

    {/* forth section */}
    <div className="row">
      <h5>{blog.title5}</h5>
      <p>{blog.title5Description}</p>
    </div>


    {/* contact information */}
    <div className="row">
      <h5>{blog.title7}</h5>
      <p>{blog.title7Description1}</p>
      <p>{blog.end} <a href={`mailto:${blog.email}`} className="text-decoration-none">{blog.email}</a> {blog.endDescription1} <a href="tel:034186087273" className="text-decoration-none" style={{ direction: "ltr" }}>+49 (0) 341 860 872 73</a> {blog.endDescription2} <a href="tel:01711976204" className="text-decoration-none" style={{ direction: "ltr" }}>0171 197 62 04.</a></p>
    </div>

    <div className="row">
      <Link to="/blog" className="text-decoration-none px-3">{blog.button}</Link>
    </div>
  </div>;
}

export default SecondBlog;
