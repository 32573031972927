import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataStore } from "../Store/Context";
import egypt from "./style/egypt.png"

function AcquisitionOfDocumentPage() {

    const { data, lan } = useContext(DataStore);

    const { title, titleImage, titleDescription, title2, title2Description1, title2Description2, title2Description3, title2Description4, title2Description5, title2Description6, title2Description7, text, title3, title3Description1, title3Description2, contactTitle, contactPersonEmail, contactPersonTel } = data[0].documentPageData[0];
    const { offerText1, offerText2, offerButton, email, phone } = data[0].homePageData[0];

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, []);

    return (
        <div className="container px-3">
            {lan && lan === "Ar" ? <div className="container">
                {/* page Title Arabic version */}
                <div className="row mt-5 mb-5 text-center">
                    <h3>{title}</h3>
                </div>

                {/* first section with the image*/}
                <div className="row mb-4">
                    <div className="col-lg-6 col-sm-12">
                        <h5>خدماتنا للمغتربين السوريين</h5>
                        <p>هل أنت بحاجة إلى أوراق ومستندات رسمية للحصول على الجنسية الألمانية او لتثبيت زواجك في سوريا لكي تستطيع تسجيل المولود بالنفوس؟ </p>
                        <p>نحن نقدم لكم هذه الخدمة بالتعاون مع مكاتب محاماة ونوفر لكم استخراج الوثائق الحكومية والشهادات الرسمية مع الترجمة وتصديقها من الخارجية السورية ومن السفارة الألمانية ببيروت وذلك بأسرع وقت وأضمن طريقة. عادةً تأخد إجراءات الاستخراج والتصديق من 6 إلى7 أسابيع، كما تشمل خدمتنا توصيل الوثائق  المطلوبة لغاية منزلك.</p>
                        <p> مكتبنا يقوم بجميع هذه الإجراءات بأسرع وأضمن طريقة ممكنة.</p>
                        <p> كما نبقى معكم على تواصل إلى أن تنتهي جميع الإجراءات  ويتم تسليم الأوراق لكم.</p>
                        <p>استخراج الأوراق الهامة هي الخطوة الأولى والأهم للقيام بأي إجراءات قانونية لاحقة.</p>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <img src={titleImage} alt="" className="img-fluid rounded" />
                    </div>
                </div>

                {/* second section and description*/}
                <div className="row mb-4">
                    <p>إذا كان بلدك الأم هو سوريا أو لبنان فإنك تستطيع الإعتماد على مكتبنا لاستخراج وتصديق الأوراق التالية:</p>
                    <ul className="px-5">
                        <li>بيان عائلي، بيان ولادة، إخراج قيد فردي، إخراج قيد عائلي، بيان وفاة</li>
                        <li>بيان زواج, عقد زواج, بيان طلاق, وثيقة طلاق، تسجيل زواج, تسجيل مولود</li>
                        <li>الشهادة المدرسية (صف تاسع، عاشر، الحادي عشر، البكلوريا)</li>
                        <li>شهادات المعاهد و الجامعات، مصدقة التخرج، كشف العلامات، توصيف المواد</li>
                        <li>شهادات الخبرة (شهادة الخبرة للمدرسين والموظفين)</li>
                        <li>شهادة لا حكم عليه</li>
                        <li>وأوراق أخرى</li>
                    </ul>
                </div>

                {/* third section and description*/}
                <div className="row mb-4">
                    <h5>خدماتنا للمغتربين المصريين</h5>
                    <p>مكتبنا يقوم بخدمات خاصة للمغتربين المصرين (استخراج وتصديق أوراق من مصر) وذلك بالتعاون مع عدة مكاتب متخصصة في هذا المجال.</p>
                    <p>تشمل خدماتنا استخراج الأوراق والمستندات التالية:</p>
                    <p className="mb-0">✔️ الوثائق الحكومية المصرية التالية</p>
                    <ul className="px-5">
                        <li>شهادات الميلاد</li>
                        <li>تسجيل مواليد الخارج</li>
                        <li>الزواج</li>
                        <li>توثيق الزواج</li>
                        <li>الجنسية المصرية</li>
                        <li>الطلاق</li>
                        <li>الوفاة</li>
                        <li>القيد الفردي والعائلي</li>
                        <li>وخدمات أخرى</li>
                    </ul>
                    <p className="mb-0">✔️ الشهادات العلمية</p>
                    <ul className="px-5">
                        <li>إستخراج شهادات المدارس والمعاهد والجامعات</li>
                        <li> استخراج كشف المواد وبيان الدرجات</li>
                        <li>الترجمة والتوثيق من الخارجية المصرية أو من السفارات الأجنبية في مصر</li>
                    </ul>
                    <p className="mb-0">✔️ إستخراج الصحيفة الجنائية (فيش وتشبيه)</p>
                    <p>كما نبقى معكم على تواصل إلى أن تنتهي جميع الإجراءات  ويتم تسليم الأوراق لكم.</p>
                </div>

                <div className="col-lg-6 mb-5">
                    <img src={egypt} alt="" />
                </div>

                {/* forth section and description */}
                <div className="row mb-4">
                    <h5>الوكالات من كاتب العدل</h5>
                    <p>هل أنتم بحاجة لسند توكيل عام أو وكالة خاصة لغرض الزواج ,الطلاق أو حصر الإرث؟</p>
                    <p>الوكالة الصحيحة هي الوكالة التي يتم مراعاة كل خطوة من خطواتها بدقة وعناية، وهي التي تقود إلى إجراءات سليمة قانونياً حيث تستطيع الحصول على أوراقك المصدقة بكل سهولة.</p>
                    <p>نوفر لكم مساعدة في كل خطوات الوكالة الرسمية والمصدقة وذلك بالتعاون مع مكاتب محاماة متخصصة، ونضمن لكم  تسليم الوكالة وفق الوقت المحدد لها مسبقا.</p>
                </div>

                {/* contact person */}
                <div className="row mt-5 d-flex align-items-center">
                    <div className="row mb-1"><h5>{contactTitle}</h5></div>
                    {lan && lan === "Ar" ? <div className="col-lg-4 col-sm-12 mb-5" >
                        <h6 className="mb-0"> عن طريق الإيميل: <a className="text-decoration-none" href={`mailto:${contactPersonEmail}`}>{contactPersonEmail}</a></h6>
                        <h6 > عن طريق الهاتف: <a className="text-decoration-none" href={`tel:${contactPersonTel}`}>{contactPersonTel}</a> </h6>
                    </div> : <div className="col-lg-4 col-sm-12 mb-5" style={{ direction: "ltr" }}>
                        <p className="mb-0">{email} <a className="text-decoration-none" href={`mailto:${contactPersonEmail}`}>{contactPersonEmail}</a></p>
                        <p >Tel.: <a className="text-decoration-none" href={`tel:${contactPersonTel}`}>{contactPersonTel}</a></p>
                    </div>}

                </div>
            </div>

                :
                <div className="container">
                    {/* page Title English and German version*/}
                    <div className="row mt-5 mb-5 text-center">
                        <h3>{title}</h3>
                    </div>

                    {/* first section with the image*/}
                    <div className="row mb-4">
                        <div className="col-lg-6">
                            <p>{titleDescription}</p>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <img src={titleImage} alt="" className="img-fluid rounded" />
                        </div>
                    </div>

                    {/* second section and description*/}
                    <div className="row mb-4">
                        <h5>{title2}</h5>
                        <ul className="px-5">
                            <li>{title2Description1}</li>
                            <li>{title2Description2}</li>
                            <li>{title2Description3}</li>
                            <li>{title2Description4}</li>
                            <li>{title2Description5}</li>
                            <li>{title2Description6}</li>
                            <li>{title2Description7}</li>
                        </ul>
                    </div>

                    {/* forth section */}
                    <div className="row mb-4">
                        <p>{text}</p>
                    </div>

                    {/* fifth section*/}
                    <div className="row mb-4">
                        <h5>{title3}</h5>
                        <p>{title3Description1}</p>
                        <p>{title3Description2}</p>
                    </div>

                    {/* contact person */}
                    <div className="row mt-5 d-flex align-items-center">
                        <div className="row mb-1"><h5>{contactTitle}</h5></div>
                        {lan && lan === "Ar" ? <div className="col-lg-4 mb-5" >
                            <h5 className="mb-0"> عن طريق الإيميل: <a className="text-decoration-none" href={`mailto:${contactPersonEmail}`}>{contactPersonEmail}</a></h5>
                            <h5 > عن طريق الهاتف: <a className="text-decoration-none" href={`tel:${contactPersonTel}`}>{contactPersonTel}</a> </h5>
                        </div> : <div className="col-lg-4 mb-5" style={{ direction: "ltr" }}>
                            <p className="mb-0">{email} <a className="text-decoration-none" href={`mailto:${contactPersonEmail}`}>{contactPersonEmail}</a></p>
                            <p >{phone} <a className="text-decoration-none" href={`tel:${contactPersonTel}`}>{contactPersonTel}</a></p>
                        </div>}


                    </div>
                </div>
            }

            {/* wisdom */}
            <div className="row text-center mb-5 pt-5">
                <h5>{offerText1}</h5>
                <h5>{offerText2}</h5>
            </div>
            <div className="row text-center mt-5 mb-5 d-flex justify-content-center">
                <div className="col"><Link to="/requestforquotedocument" className="btn btn-primary btn-lg">{offerButton}</Link></div>
            </div>
        </div>
    )
}

export default AcquisitionOfDocumentPage
