import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DataStore } from "../../Store/Context";

function BlogComponent() {

    const { blogData, lan } = useContext(DataStore);

    return (<div className="container">

        {/* Title */}
        {lan && lan === "Ar" ? <div className="row mt-5 mb-5 text-center"><h3>مدونة اللغة</h3>
        </div> : lan === "En" ? <div className="row mt-5 mb-5 text-center"><h3>Language Blog</h3>
        </div> : <div className="row mt-5 mb-5 text-center"> <h3>Sprachblog</h3>
        </div>}

        {/* blog body */}
        {blogData.map(item => {
            return (
                <div key={item.blogTitle} className="row my-5 py-5">
                    <div className="col-lg-8">
                        <p className="mb-1">{item.blogCreatedBy}</p>
                        <h5>{item.blogTitle}</h5>
                        <p>{item.blogText}</p>
                        <Link to={`/language blog detail view/${item.blogLink}`} className="btn my-3 btn-primary">{item.fullBlogButton}</Link>
                        
                    </div>
                    <div className="col-lg-4">
                        <img src={item.blogImage} className="img-fluid" alt="blog" />
                    </div>
                    
                </div>
            )
        })}
    </div>)
}

export default BlogComponent;
