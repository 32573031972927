export const TeamDataDe = [{
    
        personName: "Ahmed Abd El Aal",
        personRole: "INHABER & GESCHÄFTSFÜHRER",
        personImage: "/images/team/ahmed_abd_el_aal.jpg",
        personDescription: "Mit KAZA Sprachdienste habe ich den Traum von meiner eigenen Firma verwirklicht. Ich verbinde mit Hilfe von KAZA Sprachdienste die beiden Kulturen, die ich liebe, und helfe auch anderen Menschen dabei, die arabische Sprache und Kultur mit der Deutschen zu vereinen.",
        personText1: "Geboren in: Gharbiya / Ägypten",
        personText2: "Arbeitssprachen: Arabisch, Deutsch, Englisch",
        personText3: "B.A. Germanistik und Islamische Philosophie in Kairo",
        personText4: "M.A. Arabistik (Schwerpunkt Übersetzungs- und Islamwissenschaft) in Leipzig",
        personText5: "Öffentlich bestellter und allgemein beeidigter Dolmetscher und Übersetzer für die arabische Sprache",
        personText6: "Mitglied im BDÜ (Bundesverband der Dolmetscher und Übersetzer e. V.)",
        personText7: "Sprachtrainer für Deutsch als Zweitsprache und lizenzierter Telc-Prüfer für A1, A2, B1 und B2",
    },
{
        personName: "Dana Al Khawam",
        personRole: "BÜROLEITERIN & KUNDENDIENST",
        personImage: "/images/team/DanaAlkhawam.png",
        personDescription: "Um sich in einer neuen Gesellschaft wohlzufühlen, muss man offen sein für Neues und sich ständig weiterbilden. Durch meine Arbeit bei KAZA Sprachdienste konnte ich mich in vielen Bereichen weiterentwickeln, neue Erfahrungen sammeln, mich mit meinen Kollegen austauschen und von Ihrem Wissen profitieren. Bei KAZA Sprachdienste gefällt mir besonders der Teamgeist, die Arbeitsweise und die angenehme Atmosphäre.",
        personText1: "Geboren in: Damaskus / Syrien",
        personText2: "Arbeitssprachen: Arabisch, Deutsch",
        personText3: "B.A. Arabische Sprache und Literatur in Damaskus",
        personText4: "Ausbildung als Kauffrau für Büromanagement bei der DDA (Deutsche Angestellten-Akademie) in Leipzig",
        personText5: "Weiterbildung in den Bereichen Personalwesen und Personaldienstleistungen",
        personText6: "",
        personText7: "",
    },
{
        personName: "Ibraheim Al Mokdad",
        personRole: "SPRACHMITTLER",
        personImage: "images/team/ibraheim_al_mokdad.jpg",
        personDescription: "Ich unterstütze unsere Kundinnen und Kunden bei Terminen und bei der Kommunikation im Alltag, zum Beispiel bei Behördengängen und Arztbesuchen.",
        personText1: "Geboren in: Busra Al Sham / Syrien",
        personText2: "Arbeitssprachen: Arabisch, Deutsch, Englisch",
        personText3: "",
        personText4: "",
        personText5: "",
        personText6: "",
        personText7: "",
    },
{
        personName: "Vian Said",
        personRole: "PROJEKTLEITERIN NACHHILFE",
        personImage: "images/team/team.png",
        personDescription: "Mir gefällt es, in verschiedenen Bereichen tätig zu sein. Durch meinen professionellen Hintergrund ist es mir ein besonderes Anliegen, im Bereich Integration Kindern die deutsche Sprache beizubringen. Bei KAZA Sprachdienste kann ich anderen Menschen Sprache, Kultur und Bildung vermitteln und ihnen zeigen, wie sie hier in Deutschland einen Studiengang finden.",
        personText1: "Geboren in: Sulaimanya / Irak",
        personText2: "Arbeitssprachen: Kurdisch, Arabisch, Deutsch",
        personText3: "Diplom als ausgebildete Lehrerin von der Hochschule für Lehrer im Irak",
        personText4: "Ausgebildete Lehrkraft für Deutsch als zweite Fremdsprache",
        personText5: "Sprach- und Integrationslehrerin an der 66. Grundschule in Leipzig",
        personText6: "",
        personText7: "",
    },
]
/* Englisch version *****************************************************************************************/

export const TeamDataEn = [{

        personName: "Ahmed Abd El Aal",
        personRole: "OWNER & MANGING DIRECTOR",
        personImage: "/images/team/ahmed_abd_el_aal.jpg",
        personDescription: "With KAZA Sprachdienste I realized my dream of an own business. It is the place where I can connect the two different cultures that I love, and by KAZA Sprachdienste I can help other people as well to connect the Arabic language and culture with the German one.",
        personText1: "Born in: Gharbiya / Egypt",
        personText2: "Languages: Arabic, German, English",
        personText3: "B.A. German studies and Islamic philosophy in Cairo",
        personText4: "M.A. Arabic studies in Leipzig (focus on translation and Islamic studies)",
        personText5: "Publicly appointed and sworn interpreter and translator for Arabic ",
        personText6: "Member of BDÜ (Bundesverband der Dolmetscher und Übersetzer e. V.)",
        personText7: "Language trainer for German as a Second Language and certified examiner for Telc level A1, A2, B1 und B2",
    },
{
        personName: "Dana Al Khawam",
        personRole: "OFFICE MANAGER & CUSTOMER SERVICE",
        personImage: "/images/team/DanaAlkhawam.png",
        personDescription: "Who wants to integrate in a new culture has to be open-minded and ready to develop. Working at KAZA Sprachdienste gives me the chance to develop in many areas, to gain new experience, to interchange with colleagues and to benefit from their knowledge. I love especially the teamspirit and the working atmosphere at KAZA Sprachdienste.",
        personText1: "Born in: Damascus / Syria",
        personText2: "Languages: Arabic, German",
        personText3: "B.A. Arabic Language and Literature in Damascus",
        personText4: "Professional training as agent for office management at DAA (Deutsche Angestellten-Akademie) in Leipzig",
        personText5: "Professional development in the sector of Human Resources",
        personText6: "",
        personText7: "",
    },
{
        personName: "Ibraheim Al Mokdad",
        personRole: "LANGUAGE MEDIATOR",
        personImage: "images/team/ibraheim_al_mokdad.jpg",
        personDescription: "I support our clients by accompanying them to appointments and helping them to communicate in every day’s life for example when going to see an authority or the doctor.",
        personText1: "Born in: Busra Al Sham / Syria",
        personText2: "Languages: Arabic, German, English",
        personText3: "",
        personText4: "",
        personText5: "",
        personText6: "",
        personText7: "",
    },
{
        personName: "Vian Said",
        personRole: "HEAD OF DEPARTMENT PRIVATE LESSONS",
        personImage: "images/team/team.png",
        personDescription: "I enjoy working in many different areas. Due to my professional background I am especially eager in the integration of children by teaching the German language. At KAZA I have the possibility to pass on knowledge in language, culture and education and show young people how to find a study program in Germany.",
        personText1: "Born in: Sulaimanya / Iraq",
        personText2: "Languages: Kurdish, Arabic, German",
        personText3: "Diploma from the teacher’s college in Iraq",
        personText4: "Qualified language trainer for German as a Second Language",
        personText5: "Language and Integration teacher at 66. Grundschule in Leipzig",
        personText6: "",
        personText7: "",
    },
]

/* Arabic version ******************************************************************************************/


export const TeamDataAr = [{

        personName: "الأستاذ أحمد عبد العال",
        personRole: "المالك والمدير العام",
        personImage: "/images/team/ahmed_abd_el_aal.jpg",
        personDescription: "مع KAZA Sprachdienste حققت حلمي في إنشاء شركة خاصة بي. بمساعدة KAZA Sprachdienst  استطيع أن أجمع بين الثقافتين اللتين أحبهما وأساعد أيضًا الآخرين على توحيد لغة الثقافتين العربية والألمانية.",
        personText1: "ولدت في الغربية / مصر ",
        personText2: "أتكلم اللغة العربية، الألمانية والإنكليزية",
        personText3: "حاصل على درجة البكالوريوس في اللغة الألمانية والفلسفة الإسلامية من جامعة الأزهر بالقاهرة",
        personText4: "حاصل على ماجستير في الدراسات العربية (الترجمة والدراسات الاسلامية) من جامعة لايبزيغ. ",
        personText5: "مترجم محلّف للترجمة الشفوية والتحريرية (عربي –ألماني –عربي)",
        personText6: " وعضو في اتحاد المترجمين الألمان (BDÜ).",
        personText7: " مدرس اللغة الألمانية كـ لغة أجنبية وممتحن معتمد من معهد Telc للعديد من المستويات اللغوية A1 ، A2 ، B1 und B2.",
    },
{
        personName: " دانا الخوام ", 
        personRole: "مديرة المكتب والمسؤولة عن خدمة العملاء",
        personImage: "/images/team/DanaAlkhawam.png",
        personDescription: "من خلال عملي في شركة KAZA Sprachdienste للخدمات اللغوية تمكنت من تطوير نفسي في العديد من المجالات واكتساب خبرات جديدة بالإضافة إلى تبادل الأفكار مع زملائي والاستفادة من خبراتهم وما أعجبني بالذات في شركة KAZA للخدمات اللغوية هو روح الفريق وكذلك طبيعة وأجواء العمل",
        personText1: "ولدت في دمشق / سوريا. ",
        personText2: "أتكلم اللغة العربية والألمانية.",
        personText3: "حاصلة على بكالوريوس في اللغة العربية وآدابها من جامعة دمشق",
        personText4: "حاصلة من ألمانيا على شهادة التدريب المهني في مجال الوظائف التجارية وإدارة الأعمال والمكاتب من الأكاديمية الألمانية للتوظيف في مدينة لايبزيغ ",
        personText5: "حاصلة على تخصص في مجال الموارد البشرية وشؤون الموظفين من الأكاديمية الألمانية للتوظيف DAA.",
        personText6: "",
        personText7: "",
    },
{
        personName: "إبراهيم المقداد",
        personRole: "مترجم ومسؤول عن قسم لم الشمل",
        personImage: "images/team/ibraheim_al_mokdad.jpg",
        personDescription: "أقوم بتقديم المساعدة للعملاء في الترجمة والتواصل أثناء مواعيدهم في الدوائر الحكومية أو عند الطبيب ",
        personText1: "ولدت في بصرى / سوريا.",
        personText2: "أتكلم اللغة العربية، الألمانية والإنكليزية بطلاقة.",
        personText3: "",
        personText4: "",
        personText5: "",
        personText6: "",
        personText7: "",
    },
{
        personName: "فيان السعيد",
        personRole: "المسؤولة عن دروس التقوية وإيجاد فرص عمل",
        personImage: "images/team/team.png",
        personDescription: "عملي في KAZA Sprachdienste كمسؤولة عن حصص التقوية الدراسية والبحث عن فرص العمل في ألمانيا أعطاني الفرص الكثيرة لأنمي قدراتي في شتى المجالات وإنجاز عملي بشكل أفضل وكيفية الربط بين التنمية الذاتية ومساعدة الأخرين.",
        personText1: " ولدت: في السليمانية / العراق ",
        personText2: "أتكلم اللغة العربية والألمانية واللغة الكردية ",
        personText3: " دبلوم: كلية المعلمين في العراق",
        personText4: " التعليم: دازر داف مدرس اللغة الألمانية كلغة ثانية",
        personText5: " منسق التدريس والبحث عن عمل في ألمانيا",
        personText6: "",
        personText7: "",
    },
]
