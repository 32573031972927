import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import { DataStore } from "../Store/Context";

function NotFoundPage() {

  const { lan } = useContext(DataStore);
  return (
    <div className="container">
    {lan && lan === "Ar" ?
      <div className="row text-center d-flex justify-content-center align-items-center py-5">
        <h1 className="py-5">404 - الصفحة غير موجودة الرجاء التأكد من الرابط</h1>
        <Link to="/" className="text-decoration-none px-2">الرجوع للصفحة الرئيسية</Link>
      </div>
      : lan && lan === "En" ?
      <div className="row text-center d-flex justify-content-center align-items-center py-5">
        <h1 className="py-5">404 - Page Not Found!</h1>
        <Link to="/" className="text-decoration-none px-2">Back to Home Page</Link>
      </div> :
      <div className="row text-center d-flex justify-content-center align-items-center py-5">
        <h1 className="py-5">404 - Seite nicht gefunden!</h1>
        <Link to="/" className="text-decoration-none px-2">Zurück zu Start Seite</Link>
      </div>}
    </div>
  )
}

export default NotFoundPage