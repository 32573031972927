import React, { useEffect } from 'react'

function Authentication({ setServiceWithAuthentication, serviceWithAuthentication, serviceWithAuthentication_, serviceWithAuthentication_2,
    serviceLand }) {



    useEffect(() => {

    }, [serviceWithAuthentication_])

    return (

        <div className="col-lg-6 col-sm-12 pt-2 d-flex">
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="serviceWithAuthentication" role="switch" name="serviceWithAuthentication" onChange={() => setServiceWithAuthentication(!serviceWithAuthentication)} checked={serviceWithAuthentication} style={{ cursor: 'pointer' }} />
                <label htmlFor="serviceWithAuthentication" className="form-check-label" style={{ cursor: 'pointer' }}>{serviceLand === "مصر" ? serviceWithAuthentication_2 : serviceLand === "Ägypten" ? serviceWithAuthentication_2 : serviceLand === "Egypt" ? serviceWithAuthentication_2 : serviceWithAuthentication_}</label>
            </div>
        </div>

    )
}

export default Authentication