export const blogDataDe = [
    {
        blogCreatedBy: "Von KAZA Sprachdienste",
        blogImage: "/images/blog/9Mars.jpg",
        blogTitle: "20 Geheimnisse der arabischen Sprache",
        blogText: "Lernbegeisterten aus dem europäischen Sprachraum kann das arabische Sprachsystem anfangs wie ein Buch mit sieben Siegeln erscheinen: Eine neue Schrift, von rechts nach links geschrieben, mit fremden Buchstaben und einer ungewohnten Aussprache, die zu Beginn vermeintliche Zungenakrobatiken verlangt. Sitzen jedoch die Grundlagen, geht die Reise erst so richtig los: Hinter jeder Sprache verbirgt sich eine Struktur, die es zu entdecken gilt. Dann sind die Geheimnisse der arabischen Sprache plötzlich gar nicht mehr so geheim und helfen dabei, die Besonderheit und Schönheit der Sprache noch einmal anders wertzuschätzen. Um Einsteigern und Einsteigerinnen schon zu Beginn einen kleinen Einblick zu ermöglichen, haben wir hier einmal 20 „Geheimnisse“ zusammengetragen.",
        fullBlogButton: "WEITERLESEN …",
        blogLink: "first blog"
    },
    {
        blogCreatedBy: "Von KAZA Sprachdienste",
        blogImage: "/images/blog/1Mars.jpg",
        blogTitle: "Die arabische Sprache – schnell zu lernen?",
        blogText: "Beim Sprachenlernen muss jeder Beginner sein individuelles Lerntempo finden. Dieses hängt von mehreren Faktoren ab: Die Zeit, die man für das Lernen aufbringen kann, spielt dabei eine wichtige Rolle. Je öfter man übt, desto schneller zeigen sich Fortschritte. Auch andere, bereits bekannte Sprachen können beim Erlernen einer neuen Sprache hilfreich sein. Durch das Aufdecken von Gemeinsamkeiten und Unterschieden lassen sich Strukturen leichter erkennen und merken. Zugegeben, auf den ersten Blick haben Deutsch und Arabisch nicht viel gemeinsam. Bei genauerem Hinsehen aber schon.",
        fullBlogButton: "WEITERLESEN …",
        blogLink: "second blog"
    },
    {
        blogCreatedBy: "Von KAZA Sprachdienste",
        blogImage: "/images/blog/5Feb.jpg",
        blogTitle: "Die Aufgaben eines Sprachdienstleisters – deshalb lohnt sich die Zusammenarbeit",
        blogText: "Sprache begleitet uns jeden Tag, seit wir als Kinder gelernt haben, Wörter aneinanderzufügen, um uns zu verständigen. Wer mit welcher Sprache aufwächst, hängt vom Geburtsland, der Muttersprache der Eltern und weiteren Faktoren ab. Fast die Hälfte der Weltbevölkerung spricht eine der zehn häufigsten Sprachen als Muttersprache.",
        fullBlogButton: "WEITERLESEN …",
        blogLink: "third Blog"
    },
]
/* English version *****************************************************************/
export const blogDataEn = [
    {
        blogCreatedBy: "By KAZA Sprachdienste",
        blogImage: "/images/blog/9Mars.jpg",
        blogTitle: "20 secrets of the Arabic language",
        blogText: "To many enthusiastic learners the Arabic language may seem at first sight like a closed book: A new writing, from right to left, with beautiful but weird letters and an unknown pronunciation that seems to need acrobatics of the tongue. However, all of that is only the beginning and the real journey starts after learning the basics: Behind each language there is a special structure to discover. And suddenly the secrets of the Arabic language become less secret and more obvious as they help to appreciate the special beauty of the language more and more. With the purpose to open a first insight in the language to beginners, we collected 20 “secrets” of the Arabic language:",
        fullBlogButton: "READ MORE ...",
        blogLink: "first blog"
    },
    {
        blogCreatedBy: "By KAZA Sprachdienste",
        blogImage: "/images/blog/1Mars.jpg",
        blogTitle: "The Arabic language - easy to learn ?",
        blogText: "When learning a language, every beginner has to find their individual learning pace. This depends on several factors: The time that can be spent on learning plays an important role. The more you practice, the faster you make progress. Other languages ​​you already know can also be helpful when learning a new language. By uncovering similarities and differences, structures can be recognized and remembered more easily. Admittedly, at first glance, German and Arabic don't have much in common. But on closer inspection it is.",
        fullBlogButton: "READ MORE ...",
        blogLink: "second blog"
    },
    {
        blogCreatedBy: "By KAZA Sprachdienste",
        blogImage: "/images/blog/5Feb.jpg",
        blogTitle: "The tasks of a language service provider – why cooperation is worth",
        blogText: "Language has been with us every day since we learned to put words together as children to communicate. Who grows up with which language depends on the country of birth, the mother tongue of the parents and other factors. Almost half of the world's population speaks one of the ten most common languages ​​as their mother tongue.",
        fullBlogButton: "READ MORE …",
        blogLink: "third Blog"
    },
]

/* Arabic version *****************************************************************/
export const blogDataAr = [
    {
        blogCreatedBy: "تم التحرير بواسطة KAZA Sprachdienste",
        blogImage: "/images/blog/9Mars.jpg",
        blogTitle: "20 سر من أسرار اللغة العربية",
        blogText: "قد يرى المتعلمون المتحمسون من منطقة اللغة الأوروبية في البداية نظام اللغة العربية ككتاب مغلق: نص جديد ، مكتوب من اليمين إلى اليسار ، بحروف أجنبية ونطق غير مألوف ، والذي يتطلب في البداية حركات بهلوانية لسان مزعومة. ومع ذلك ، بمجرد أن يتم وضع الأساسيات ، تبدأ الرحلة حقًا: خلف كل لغة هناك بنية يجب اكتشافها. ثم تصبح أسرار اللغة العربية فجأة غير سرية للغاية وتساعد على تقدير خصوصية اللغة وجمالها بطريقة مختلفة. من أجل إعطاء المبتدئين فكرة بسيطة منذ البداية ، قمنا بتجميع 20 سرًا هنا.  ",
        fullBlogButton: "أكمل القراءة …",
        blogLink: "first blog"
    },
    {
        blogCreatedBy: "تم التحرير بواسطة KAZA Sprachdienste",
        blogImage: "/images/blog/1Mars.jpg",
        blogTitle: "اللغة العربية - لتتعلم بسرعة؟",
        blogText: "عند تعلم لغة ما ، يجب على كل مبتدئ أن يجد سرعة التعلم الفردية الخاصة به. يعتمد هذا على عدة عوامل: يلعب الوقت الذي يمكن قضاؤه في التعلم دورًا مهمًا. كلما تدربت أكثر ، كلما أحرزت تقدمًا أسرع. يمكن أن تكون اللغات الأخرى التي تعرفها بالفعل مفيدة عند تعلم لغة جديدة. من خلال الكشف عن أوجه التشابه والاختلاف ، يمكن التعرف على الهياكل وتذكرها بسهولة أكبر. من المسلم به ، للوهلة الأولى ، أنه لا يوجد الكثير من القواسم المشتركة بين الألمانية والعربية. ولكن عند الفحص الدقيق هو كذلك.",
        fullBlogButton: "أكمل القراءة …",
        blogLink: "second blog"
    },
    {
        blogCreatedBy: "تم التحرير بواسطة KAZA Sprachdienste",
        blogImage: "/images/blog/5Feb.jpg",
        blogTitle: "مهام مزود خدمة اللغة - لهذا التعاون جدير بالاهتمام",
        blogText: "كانت اللغة معنا كل يوم منذ أن تعلمنا كأطفال أن نجمع الكلمات معًا للتواصل. من يكبر مع أي لغة تعتمد على بلد الميلاد واللغة الأم للوالدين وعوامل أخرى. يتحدث نصف سكان العالم تقريبًا إحدى اللغات العشر الأكثر شيوعًا كلغتهم الأم.",
        fullBlogButton: "أكمل القراءة …",
        blogLink: "third Blog"
    },
]

/* ************************************* the complete blog ********************************************/

export const firstFullBlogData = [{
    firstBlogDe: [{
        title: "20 Geheimnisse der arabischen Sprache",
        createdAt: "09.03.2021 von KAZA Sprachdienste",
        imageTitle: "/images/blog/9Mars.jpg",
        text1: "Lernbegeisterten aus dem europäischen Sprachraum kann das arabische Sprachsystem anfangs wie ein Buch mit sieben Siegeln erscheinen: Eine neue Schrift, von rechts nach links geschrieben, mit fremden Buchstaben und einer ungewohnten Aussprache, die zu Beginn vermeintliche Zungenakrobatiken verlangt. Sitzen jedoch die Grundlagen, geht die Reise erst so richtig los: Hinter jeder Sprache verbirgt sich eine Struktur, die es zu entdecken gilt. Dann sind die Geheimnisse der arabischen Sprache plötzlich gar nicht mehr so geheim und helfen dabei, die Besonderheit und Schönheit der Sprache noch einmal anders wertzuschätzen. Um Einsteigern und Einsteigerinnen schon zu Beginn einen kleinen Einblick zu ermöglichen, haben wir hier einmal 20 „Geheimnisse“ zusammengetragen. ",
        title2: "Einige Geheimnisse der arabischen Sprache",
        title2Description1: "Insgesamt ist Arabisch in 26 Ländern Amtssprache und wird dadurch von rund 313 Millionen Menschen muttersprachlich gesprochen. Kein Wunder also, dass die arabische Sprache ebenso eine der sechs offiziellen Sprachen der Vereinten Nationen darstellt.",
        title2Description2: "Mit rund 97 Millionen Einwohnern ist Ägypten das Land mit den meisten MuttersprachlerInnen.",
        title2Description3: "Die Ursprünge der arabischen Sprache liegen im Gebiet des heutigen Jordaniens. Dort bildete sich etwa im 2. Jh. v. Chr. der Vorläufer des Arabischen – das Nabatäische – als Dialekt aus dem Aramäischen heraus.",
        title2Description4: "Der Siegeszug der arabischen Sprache begann mit der Verbreitung des Korans. Dieser wurde zunächst mündlich, ausgehend vom Propheten Mohammed, überliefert. Erst nach dessen Tod im Jahr 632 n. Chr. wurden seine Offenbarungen von verschiedenen Gelehrten niedergeschrieben. Durchgesetzt haben sich die Schriften des Kalifen Uthman, welche die Basis für den heutigen Koran bilden.",
        title3: "Der Koran als wahres Geheimnis der arabischen Sprache",
        title3Description1: "Der Koran gilt als das heiligste Gut des Islams und stellt daher das Idealbild der arabischen Grammatik und Orthografie dar. Ihm entstammt das Hocharabisch, welches in allen arabischsprachigen Ländern neben den einzelnen Dialekten erlernt wird.",
        title3Description2: "Genau genommen darf der Koran nur in der Originalsprache vorgetragen werden, da sich sonst inhaltliche Ungenauigkeiten oder gar Fehler durch die Übersetzungen ergeben können.",
        title3Description3: "Kalligrafie, also die Kunst der Schönschreibens ist ein zentraler Bestandteil der arabischen Kultur – aufgrund des Korans: Er verbietet Abbildungen von Allah und seiner Schöpfung. Aus diesem Grund begannen die Menschen, den Koran auf andere Art in ihren Moscheen und heiligen Bauwerken zu verewigen – in Form kunstvoll geschriebener Verszeilen.",
        title4: "Grammatische Besonderheiten der arabischen Sprache",
        title4Description1: "Im Gegensatz zum Deutschen, welches streng festgelegte Satzzeichenregeln hat, nimmt es das Arabische mit Punkt und Komma nicht so genau: Satzzeichen sind zumeist freiwillig zu setzen und dienen lediglich dem besseren Verständnis.",
        title4Description2: "Neben ihrer Eigenart als Konsonantenschrift besteht das arabische Alphabet ausschließlich aus Kleinbuchstaben.",
        title4Description3: "Es werden nur männliche und weibliche Formen unterschieden, ein Neutrum wie im Deutschen gibt es nicht. Dafür ist die Teilung aber konsequent: Im Arabischen werden auch Verben unterschiedlich konjugiert, je nachdem, ob es sich beim Subjekt um einen Mann oder eine Frau handelt. Eine Ausnahme bilden dabei nur die ersten Personen in Singular und Plural (Ich- und Wir-Form), welche grammatisch nicht nach weiblich oder männlich unterschieden werden.",
        title4Description4: "Verben besitzen keine separate Infinitivform – in Wörterbüchern wird stattdessen die männliche Form der 3. Person Singular verwendet.",
        title4Description5: "Adjektive und Substantive werden häufig durch das gleiche Wort bezeichnet, zum Beispiel im Fall von „groß“ und „Größe“. Was genau gemeint ist, ergibt sich aus dem Kontext.",
        title4Description6: "Im Gegensatz zum Deutschen werden im Arabischen lediglich drei Kasusformen unterschieden: Nominativ, Genitiv und Akkusativ.",
        title5: "Zu zweit – das duale Geheimnis der arabischen Sprache",
        title5Description1: "Neben Singular und Plural ist im Arabischen bis heute eine weiterer Numerus erhalten geblieben: der Dual. Dieser wird verwendet, wenn etwas genau zwei Mal vorkommt: zwei Hände, zwei Arme, zwei Beine.",
        title5Description2: "Als wäre der Dual für Deutsche nicht schon ungewohnt genug, teilt sich auch der bekannte Plural in zwei Teile: Man unterscheidet gesunder von gebrochener Pluralbildung. Bei ersterer wird die Einzahl zur Mehrzahl, indem schlichtweg eine Endung angehangen wird. Ein gebrochener Plural bedeutet hingehen, dass innerhalb des Wortes Veränderungen stattfinden, um Singular zum Plural zu formen.",
        title5Description3: "Auch die Zeitgestaltung des Arabischen ist von Dualität geprägt: Statt auf Zeitformen wie Präsens oder Präteritum stützt sich das Arabische vor allem auf so genannte Aspekte: perfektive Verben bezeichnen bereits abgeschlossene Handlungen oder Prozesse, während imperfektive Verben noch andauernde Tätigkeiten beschreiben. An dieser Stelle zeigt sich, wie hilfreich bereits bekannte Sprachen sein können: Russischkennern und -kennerinnen beispielsweise dürfte dieses System bereits bekannt vorkommen.",
        title6: "Weitere Fakten über die arabische Sprache",
        title6Description1: "Sie hat einen sehr geringen Lehn- und Fremdwortanteil.",
        title6Description2: "Arabisch besitzt einen sehr umfangreichen, bildhaften Wortschatz: das Wort „Liebe“ lässt sich zum Beispiel auf 15 verschiedene Arten ausdrücken.",
        title6Description3: "Arabische Ziffern – die wir übrigens im Deutschen in abgewandelter Form ebenso verwenden – werden entgegen der normalen Schriftrichtung geschrieben: Die Zahl 10 wird auch im Arabischen von links nach rechts, als „10“, geschrieben, und nicht, wie vielleicht zu vermuten wäre, „01“.",
        title6Description4: "Die arabische Aussprache berührt das Herz. Und das nicht nur im übertragenen Sinne: Eine Publikation der Beuth Hochschule Berlin zeigt, dass die arabische Lautbildung im Sinne der Akkupressurtechnik einen positiven Einfluss auf die Gesundheit hat. Demnach stimulierten die so genannten Intensivierungslaute im Arabischen Akkupressurpunkte auf der Zunge. Somit hätten beispielsweise Buchstaben, die mithilfe der Zungenspitze gebildet werden, einen anregenden Einfluss auf die Herztätigkeit.",
        title7: "Sie möchten noch mehr Geheimnisse der arabischen Sprache entdecken?",
        title7Description1: "Dann unterstützen wir Sie gern, denn Sprache lebt von Austausch und Kontakt. Unsere Arabisch-MuttersprachlerInnen helfen Ihnen, die Sprache auf eine nahe und lebendige Art und Weise kennenzulernen.",
        end: "Erfahren Sie mehr unter: ",
        endDescription1: "oder telefonisch: ",
        endDescription2: "sowie Mobil: ",
        email: "info@kaza-sprachdienste.de",
        button: "Zurück"
    }],
    firstBlogEn: [{
        title: "20 Secrets of the Arabic Language",
        createdAt: "09.03.2021 by KAZA Sprachdienste",
        imageTitle: "/images/blog/9Mars.jpg",
        text1: "To learners from the European language area, the Arabic language system can initially seem like a closed book: A new script, written from right to left, with foreign letters and an unfamiliar pronunciation, which initially requires supposed tongue acrobatics. But the basics are there , the journey really begins: Behind every language there is a structure that needs to be discovered. Then the secrets of the Arabic language are suddenly no longer so secret and help to appreciate the specialness and beauty of the language in a different way . In order to give beginners a little insight right from the start, we have compiled 20 secrets here. ",
        title2: "Some Secrets of the Arabic Language",
        title2Description1: "Arabic is the official language in 26 countries and is therefore spoken by around 313 million people as their mother tongue. So it's no wonder that Arabic is also one of the six official languages of the United Nations.",
        title2Description2: "With around 97 million inhabitants, Egypt is the country with the most native speakers.",
        title2Description3: "The origins of the Arabic language lie in what is now Jordan. Around the 2nd century BC, the forerunner of Arabic – Nabataean – developed as a dialect from Aramaic.",
        title2Description4: "The triumph of the Arabic language began with the spread of the Koran. This was first handed down orally, starting with the Prophet Mohammed. It was only after his death in 632 AD that his revelations were written down by various scholars. The Writings of Caliph Uthman, which form the basis for today's Koran.",
        title3: "The Koran as the true secret of the Arabic language",
        title3Description1: "The Koran is considered the most sacred property of Islam and therefore represents the ideal image of Arabic grammar and orthography. Standard Arabic comes from it, which is learned in all Arabic-speaking countries alongside the individual dialects.",
        title3Description2: "Strictly speaking, the Koran may only be recited in the original language, otherwise inaccuracies in content or even errors may result from the translations.",
        title3Description3: "Calligraphy, i.e. the art of beautiful writing, is a central part of Arabic culture - because of the Koran: it forbids depictions of Allah and his creation. Because of this, people began to display the Koran differently in their mosques and holy buildings immortalize - in the form of artistically written lines of verse.",
        title4: "Grammatic Peculiarities of the Arabic Language",
        title4Description1: "In contrast to German, which has strictly defined punctuation rules, Arabic doesn't take periods and commas so precisely: punctuation marks are usually to be used voluntarily and only serve to improve understanding.",
        title4Description2: "Besides being a consonant script, the Arabic alphabet consists entirely of lowercase letters.",
        title4Description3: "Only male and female forms are distinguished, there is no neuter as in German. But the division is consistent: In Arabic, verbs are also conjugated differently, depending on whether the subject is a man or a woman The only exception to this is the first persons in singular and plural (I and we form), which are not grammatically differentiated according to female or male.",
        title4Description4: "Verbs do not have a separate infinitive form – dictionaries use the 3rd person singular masculine form instead.",
        title4Description5: "Adjectives and nouns are often denoted by the same word, for example in the case of big and size. Exactly what is meant depends on the context.",
        title4Description6: "In contrast to German, Arabic only distinguishes three case forms: nominative, genitive and accusative.",
        title5: "For Two – The Dual Mystery of the Arabic Language",
        title5Description1: "In addition to the singular and plural, another number has survived in Arabic: the dual. This is used when something occurs exactly twice: two hands, two arms, two legs.",
        title5Description2: "As if the dual wasn't unfamiliar enough for Germans, the well-known plural is also divided into two parts: A distinction is made between healthy and broken plural formation. In the former, the singular becomes the plural, by simply ending is appended. A broken plural to go means that changes take place within the word to form singular to plural.",
        title5Description3: "The tense structure of Arabic is also characterized by duality: Instead of using tenses such as present or past tense, Arabic relies primarily on so-called aspects: perfective verbs describe actions or processes that have already been completed, while imperfective verbs describe activities that are still ongoing This shows how helpful familiar languages ​​can be: those who know Russian, for example, should already be familiar with this system.",
        title6: "More facts about the Arabic language",
        title6Description1: "It has a very small proportion of borrowed and foreign words.",
        title6Description2: "Arabic has a very large, figurative vocabulary: for example, the word love can be expressed in 15 different ways.",
        title6Description3: "Arabic numerals - which we also use in German in a modified form - are written in the opposite direction to the normal writing direction: The number 10 is also written in Arabic from left to right, as 10, and not as you might expect would be, 01.",
        title6Description4: "Arabic pronunciation touches the heart. And not just in a figurative sense: A publication by the Beuth University of Applied Sciences in Berlin shows that Arabic sound formation in the sense of acupressure technique has a positive effect on health. According to this, the so-called intensifying sounds in Arabic have a stimulating effect Acupressure points on the tongue. For example, letters formed with the tip of the tongue would have a stimulating effect on heart activity.",
        title7: "Want to discover more secrets of the Arabic language?",
        title7Description1: "Then we would be happy to support you, because language thrives on exchange and contact. Our Arabic native speakers will help you to get to know the language in a close and lively way.",
        end: "Learn more at: ",
        endDescription1: "or by phone: ",
        endDescription2: "and Mobile: ",
        email: "info@kaza-sprachdienste.de",
        button: "Back"
    }],
    firstBlogAr: [{
        title: "20 اسرار اللغة العربية",
        createdAt: "09.03.2021 بواسطة KAZA Sprachdienste",
        imageTitle: "/images/blog/9Mars.jpg",
        text1: "بالنسبة للمتعلمين من منطقة اللغة الأوروبية ، يمكن أن يبدو نظام اللغة العربية في البداية وكأنه كتاب مغلق: نص جديد ، مكتوب من اليمين إلى اليسار ، بأحرف أجنبية ونطق غير مألوف ، والذي يتطلب في البداية حركات بهلوانية لسان مفترضة. ولكن الأساسيات موجودة ، تبدأ الرحلة حقًا: خلف كل لغة هناك بنية يجب اكتشافها. ثم فجأة لم تعد أسرار اللغة العربية سرية للغاية وتساعد على تقدير خصوصية اللغة وجمالها بطريقة مختلفة . من أجل إعطاء المبتدئين فكرة بسيطة منذ البداية ، قمنا بتجميع 20 سرًا هنا. ",
        title2: "بعض أسرار اللغة العربية",
        title2Description1: "اللغة العربية هي اللغة الرسمية في 26 دولة وبالتالي يتحدث بها حوالي 313 مليون شخص كلغتهم الأم. لذلك فلا عجب أن اللغة العربية هي أيضًا إحدى اللغات الرسمية الست للأمم المتحدة.",
        title2Description2: "مع حوالي 97 مليون نسمة ، تعد مصر البلد الأكثر ناطقًا باللغة.",
        title2Description3: "تكمن أصول اللغة العربية في ما يعرف الآن بالأردن. حوالي القرن الثاني قبل الميلاد ، نشأت اللغة العربية - النبطية - كلهجة من الآرامية.",
        title2Description4: "بدأ انتصار اللغة العربية بانتشار القرآن ، وقد نُقل هذا أولاً شفوياً بدءاً من النبي محمد ، ولم يتم تدوين آياته إلا بعد وفاته عام 632 م على يد علماء مختلفين. كتابات الخليفة عثمان التي تشكل أساس القرآن اليوم",
        title3: "القرآن سر اللغة العربية",
        title3Description1: "يعتبر القرآن من أقدس ممتلكات الإسلام وبالتالي يمثل الصورة المثالية لقواعد اللغة العربية والتهجئة. وتأتي منه اللغة العربية الفصحى التي يتم تعلمها في جميع البلدان الناطقة باللغة العربية جنبًا إلى جنب مع اللهجات الفردية",
        title3Description2: "بالمعنى الدقيق للكلمة ، لا يجوز تلاوة القرآن إلا باللغة الأصلية ، وإلا فقد ينتج عن الترجمات عدم دقة في المحتوى أو حتى أخطاء.",
        title3Description3: "الخط أي فن الكتابة الجميلة جزء أساسي من الثقافة العربية - بسبب القرآن: فهو يحرم تصوير الله وخلقه. ولهذا السبب بدأ الناس في عرض القرآن بشكل مختلف في مساجدهم ومقدساتهم. تخليد المباني - في شكل خطوط شعرية مكتوبة فنياً. ",
        title4: "الخصائص النحوية للغة العربية",
        title4Description1: "على عكس اللغة الألمانية ، التي حددت قواعد علامات الترقيم بدقة ، لا تأخذ اللغة العربية النقط والفواصل بدقة شديدة: عادةً ما يتم استخدام علامات الترقيم طواعية وتعمل فقط على تحسين الفهم.",
        title4Description2: "إلى جانب كونها حروفًا ساكنة ، تتكون الأبجدية العربية بالكامل من أحرف صغيرة.",
        title4Description3: "يتم تمييز صيغتي الذكر والأنثى فقط ، ولا يوجد محايد كما في الألمانية. لكن التقسيم ثابت: في اللغة العربية ، يتم تصريف الأفعال أيضًا بشكل مختلف ، اعتمادًا على ما إذا كان الفاعل رجلاً أو امرأة ، والاستثناء الوحيد لهذا هي أول الأشخاص بصيغة المفرد والجمع (أنا ونحن نشكل) ، والتي لا يتم التفريق بينها نحويًا وفقًا للأنثى أو الذكر. ",
        title4Description4: "لا تحتوي الأفعال على صيغة مصدر منفصلة - تستخدم القواميس صيغة المذكر المفرد بضمير الغائب بدلاً من ذلك.",
        title4Description5: "غالبًا ما يتم الإشارة إلى الصفات والأسماء بنفس الكلمة ، على سبيل المثال في حالة كبير و حجم . ويعتمد المقصود بالضبط على السياق.",
        title4Description6: "على عكس الألمانية ، لا تميز اللغة العربية سوى ثلاث حالات: حالة رمزية ، ومضاف إليها ، وحالة النصب.",
        title5: "لشخصين - اللغز المزدوج للغة العربية",
        title5Description1: "بالإضافة إلى صيغة المفرد والجمع ، فقد نجا رقم آخر باللغة العربية: الثنائي. يستخدم هذا عندما يحدث شيء ما مرتين بالضبط: يدان ، ذراعان ، ساقان.",
        title5Description2: "كما لو لم يكن الثنائي غير مألوف بدرجة كافية بالنسبة للألمان ، فإن صيغة الجمع المشهورة تنقسم أيضًا إلى جزأين: يتم التمييز بين تكوين الجمع السليم والجمع المكسور. في الأول ، يصبح المفرد هو الجمع ، ببساطة نهاية مُلحقة. الجمع المكسور يعني أن التغييرات تحدث داخل الكلمة لتتشكل من المفرد إلى الجمع. ",
        title5Description3: "تتميز التركيبة المتوترة للغة العربية أيضًا بالازدواجية: بدلاً من استخدام الأزمنة مثل المضارع أو الماضي ، تعتمد اللغة العربية بشكل أساسي على ما يسمى بالجوانب: تصف الأفعال المثالية الإجراءات أو العمليات التي تم إكمالها بالفعل ، بينما تصف الأفعال الناقصة الأنشطة التي لا تزال جارية يوضح هذا كيف يمكن أن تكون اللغات المألوفة مفيدة: أولئك الذين يعرفون اللغة الروسية ، على سبيل المثال ، يجب أن يكونوا على دراية بهذا النظام. ",
        title6: "مزيد من الحقائق حول اللغة العربية",
        title6Description1: "يحتوي على نسبة صغيرة جدًا من الكلمات المستعارة والأجنبية.",
        title6Description2: "اللغة العربية بها مفردات رمزية كبيرة جدًا: على سبيل المثال ، يمكن التعبير عن كلمة الحب بـ 15 طريقة مختلفة.",
        title6Description3: "الأرقام العربية - التي نستخدمها أيضًا باللغة الألمانية في شكل معدل - مكتوبة في الاتجاه المعاكس لاتجاه الكتابة العادي: الرقم 10 مكتوب أيضًا باللغة العربية من اليسار إلى اليمين ، مثل 10 ، وليس كما يمكنك يتوقع أن يكون ، 01. ",
        title6Description4: "النطق العربي يمس القلب. وليس بالمعنى المجازي فقط: يظهر منشور من قبل جامعة بوث للعلوم التطبيقية في برلين أن تكوين الصوت العربي بمعنى تقنية العلاج بالضغط له تأثير إيجابي على الصحة. ما يسمى بالأصوات المكثفة في اللغة العربية لها تأثير محفز نقاط الضغط على اللسان ، فمثلاً الحروف المتكونة بأطراف اللسان يكون لها تأثير محفز على نشاط القلب. ",
        title7: "هل تريد اكتشاف المزيد من أسرار اللغة العربية؟",
        title7Description1: "إذن يسعدنا تقديم الدعم لك ، لأن اللغة تزدهر عند التبادل والاتصال. وسيساعدك متحدثونا الأصليون باللغة العربية على التعرف على اللغة بطريقة وثيقة وحيوية.",
        end: "اعرف المزيد على:",
        endDescription1: "أو عن طريق الهاتف:",
        endDescription2: "والموبايل: ",
        email: "info@kaza-sprachdienste.de",
        button: "الرجوع"
    }]
}]

export const secondFullBlogData = [{
    secondBlogDe: [{
        title: "Die arabische Sprache – schnell zu lernen?",
        createdAt: "01.03.2021 von KAZA Sprachdienste",
        imageTitle: "/images/blog/1Mars.jpg",
        text1: "Beim Sprachenlernen muss jeder Beginner sein individuelles Lerntempo finden. Dieses hängt von mehreren Faktoren ab: Die Zeit, die man für das Lernen aufbringen kann, spielt dabei eine wichtige Rolle. Je öfter man übt, desto schneller zeigen sich Fortschritte. Auch andere, bereits bekannte Sprachen können beim Erlernen einer neuen Sprache hilfreich sein. Durch das Aufdecken von Gemeinsamkeiten und Unterschieden lassen sich Strukturen leichter erkennen und merken. Zugegeben, auf den ersten Blick haben Deutsch und Arabisch nicht viel gemeinsam. Bei genauerem Hinsehen aber schon.",
        title2: "Erster Eindruck der arabischen Sprache",
        title2Text: "Schaut ein deutscher Muttersprachler auf die Zeichen der arabischen Sprache, so geht es ihm wahrscheinlich genauso wie einer Marokkanerin, die das erste Mal ratlos auf das deutsche Alphabet blickt. Die beiden Schriftsysteme ähneln sich kaum. Und obwohl das Arabische mit seinen 28 Buchstaben lediglich zwei Zeichen mehr besitzt als die deutsche Sprache, brauchen SprachanfängerInnen zunächst etwas Durchhaltevermögen: Jedes Zeichen hat in der Praxis vier verschiedene Darstellungsmöglichkeiten. Insgesamt sind es theoretisch also 112 Zeichen, die es zu erlernen gilt. Hier ein Beispiel anhand von „Yā“, dem letzten Buchstaben des arabischen Alphabets: ",
        title2Description1: "ي als einzelner Buchstabe geschrieben (isoliert)",
        title2Description2: "يـ   am Anfang eines Wortes (initial)",
        title2Description3: "ـيـ  in der Mitte eines Wortes (medial)",
        title2Description4: "ـي  am Ende eines Wortes (final)",
        title2Text2: "Auch die Schriftrichtung des Arabischen ist für Europäer ungewohnt: Hier schreibt und liest man von rechts nach links. Dieser Lesefluss, das abweichende Schriftsystem sowie die verstärkt gutturale – von Kehlkopflauten geprägte – Aussprache gehören zu den ersten Dingen, die deutsche MuttersprachlerInnen beim Erlernen der arabischen Sprache verinnerlichen müssen. Doch, wie sagt man so schön? Aller Anfang ist schwer.",
        title3: "Weitere Besonderheiten der arabischen Sprache",
        title3Description: "Die arabische Schrift ist eine Konsonantenschrift. Vokale werden also nicht mit aufgeschrieben und müssen deshalb beim Lesen und Sprechen hinzugedacht, oder besser, gekannt werden. Und Kenntnisse über die jeweils passenden Vokale entstehen nur durch Lernen. Erleichtert wird das allerdings dadurch, dass sich die arabische Sprache, noch stärker als die deutsche, auf Vor- und Nachsilben stützt. Diese werden an ein feststehendes Grundwort, eine sogenannte Wurzel, angefügt und verändern dadurch deren Bedeutung. So hängen zum Beispiel die Wörter „schreiben“ und „Schreiber“ zusammen. Deshalb gilt: Kennt man die Bedeutung seiner Wurzel, kann man meist den groben Kontext eines Wortes einschätzen – selbst dann, wenn es sich um einen neuen Begriff handelt. Durch ihren besonderen Aufbau bietet die arabische Sprache eine nahezu unbegrenzte Vielfalt an Ausdrucksmöglichkeiten, die sich auch in der großen Zahl an Dialekten widerspiegelt.",
        title4: "Die arabische Sprache auf den zweiten Blick",
        title4Description: "Im Alltagsgespräch denken wir über den Ursprung einzelner Wörter in der Regel nicht nach. Würden wir unseren Wortschatz einmal genauer unter die Lupe nehmen, könnten wir allerdings feststellen, dass sich unsere Vorfahren einiger arabischer Wörter bedient haben. Vom „Alkohol“ über das „Sofa“ und die „Giraffe“ bis hin zum „Magazin“ – all diese Begriffe sind arabischen Ursprungs. Einmal mit dem Arabischlernen begonnen, werden Lernenden noch weitere Wortverwandte begegnen. Solche Entdeckungen können den Lernfortschritt beflügeln und interessante Einblicke in die Entwicklung der eigenen Sprache ermöglichen.",
        title5: "Die arabische Sprache erlernen – der Schlüssel zum Erfolg",
        title5Description: "An dieser Stelle könnte noch so viel gesagt werden. Doch die beste Möglichkeit, den Reichtum der arabischen Sprache kennenzulernen, ist es, einfach mit dem Lernen anzufangen. Schnelle Fortschritte sind letztlich zum größten Teil von der eigenen Motivation abhängig. Damit Ihnen diese auf Ihrem Weg stets erhalten bleibt, stehen Ihnen unsere LehrerInnen mit Rat und Tat zur Seite. Egal, ob Sie sich noch ganz am Anfang befinden oder bereits Vorkenntnisse besitzen.",
        end: "Wir freuen uns auf Ihre Anfrage unter:",
        endDescription1: "oder telefonisch: ",
        endDescription2: "sowie Mobil: ",
        email: "info@kaza-sprachdienste.de",
        button: "Zurück"
    }],
    secondBlogEn: [{
        title: "The Arabic language – to learn quickly?",
        createdAt: "03/01/2021 by KAZA Sprachdienste",
        imageTitle: "/images/blog/1Mars.jpg",
        text1: "When learning a language, every beginner has to find their own learning pace. This depends on several factors: The time that you can spend learning plays an important role. The more you practice, the faster you make progress. Others too , Languages ​​you already know can be helpful when learning a new language. Uncovering similarities and differences makes it easier to recognize and remember structures. Admittedly, at first glance German and Arabic don't have much in common. But on closer inspection, they do.",
        title2: "First impression of the Arabic language",
        title2Text: "If a German native speaker looks at the characters of the Arabic language, he probably feels the same way as a Moroccan who looks helplessly at the German alphabet for the first time. The two writing systems are hardly similar. And although the Arabic with its 28 letters only has two more characters than the German language, language beginners first need a bit of stamina: In practice, each character has four different representation options. In theory, there are a total of 112 characters that need to be learned. Here is an example using Yā, dem last letters of the Arabic alphabet: ",
        title2Description1: "ي Written as a Single Letter (Isolated)",
        title2Description2: "يـ at the beginning of a word (initial)",
        title2Description3: "ـيـ in the middle of a word (medial)",
        title2Description4: "ـي at the end of a word (final)",
        title2Text2: "The writing direction of Arabic is also unfamiliar to Europeans: Here you write and read from right to left. This reading flow, the different writing system and the more guttural pronunciation - characterized by larynx sounds - are among the first things that German native speakers learn of the Arabic language. But how do you say it? All beginnings are difficult.",
        title3: "Other Peculiarities of the Arabic Language",
        title3Description: "The Arabic script is a consonant script. Vowels are therefore not written down and therefore have to be thought of when reading and speaking, or better known. And knowledge of the appropriate vowels only arises through learning. However, this is made easier by the fact that The Arabic language, even more than German, relies on prefixes and suffixes. These are attached to a fixed basic word, a so-called root, and thereby change its meaning. For example, the words write and scribe are related Therefore, if you know the meaning of its root, you can usually estimate the rough context of a word - even if it is a new term.Due to its special structure, the Arabic language offers an almost unlimited variety of expressive possibilities also reflected in the large number of dialects.",
        title4: "The Arabic language at second glance",
        title4Description: "In everyday conversation, we usually don't think about the origin of individual words. If we took a closer look at our vocabulary, however, we could find that our ancestors used some Arabic words. From alcohol to that Sofa and the giraffe to the magazine - all these terms are of Arabic origin. Once you have started learning Arabic, learners will encounter even more related words. Such discoveries can inspire learning progress and provide interesting insights into the development of your own language enable.",
        title5: "Learning the Arabic language – the key to success",
        title5Description: "So much could be said at this point, but the best way to experience the richness of the Arabic language is to just start learning. Ultimately, rapid progress depends largely on your own motivation is always maintained on your way, our teachers are at your side with advice and action. It doesn't matter whether you are still at the beginning or already have previous knowledge.",
        end: "We look forward to your inquiry at:",
        endDescription1: "or by phone: ",
        endDescription2: "and mobile: ",
        email: "info@kaza-sprachdienste.de",
        button: "Back"
    }],
    secondBlogAr: [{
        title: "اللغة العربية - لتتعلم بسرعة؟",
        createdAt: "03/01/2021 بواسطة KAZA Sprachdienste",
        imageTitle: "/images/blog/1Mars.jpg",
        text1: "عند تعلم لغة ما ، يجب على كل مبتدئ أن يجد سرعة التعلم الخاصة به. وهذا يعتمد على عدة عوامل: يلعب الوقت الذي تقضيه في التعلم دورًا مهمًا. وكلما تدربت أكثر ، كلما أحرزت تقدمًا أسرع. يمكن أن تكون اللغات التي تعرفها بالفعل مفيدة عند تعلم لغة جديدة. إن الكشف عن أوجه التشابه والاختلاف يجعل من السهل التعرف على الهياكل وتذكرها. ومن المسلم به أنه للوهلة الأولى لا يوجد الكثير من القواسم المشتركة بين اللغتين الألمانية والعربية. ولكن عند الفحص الدقيق ، فإنهما فعل.",
        title2: "الانطباع الأول عن اللغة العربية",
        title2Text: "إذا نظر متحدث اللغة الألمانية إلى أحرف اللغة العربية ، فمن المحتمل أنه يشعر بنفس الطريقة التي يشعر بها المغربي الذي ينظر بلا حول ولا قوة إلى الأبجدية الألمانية لأول مرة. نظاما الكتابة بالكاد متشابهان. وعلى الرغم من أن اللغة العربية مع 28 حرفًا يحتوي على حرفين فقط أكثر من اللغة الألمانية ، يحتاج مبتدئي اللغة أولاً إلى القليل من القدرة على التحمل: في الممارسة العملية ، لكل حرف أربعة خيارات تمثيل مختلفة.من الناحية النظرية ، هناك ما مجموعه 112 حرفًا يجب تعلمها. هنا مثال باستخدام Yā ، dem الأخير من الأبجدية العربية: ",
        title2Description1: "ي مكتوب كحرف واحد (معزول)",
        title2Description2: "يـ في بداية الكلمة (أولية)",
        title2Description3: "ـيـ في وسط كلمة (وسطي)",
        title2Description4: "ـي في نهاية الكلمة (نهائي)",
        title2Text2: "اتجاه كتابة اللغة العربية أيضًا غير مألوف بالنسبة للأوروبيين: هنا تكتب وتقرأ من اليمين إلى اليسار. إن تدفق القراءة هذا ونظام الكتابة المختلف والنطق الأكثر حلقية - التي تتميز بأصوات الحنجرة - هي من بين الأشياء الأولى التي تستخدمها الألمانية الناطقون باللغة العربية يتعلمون اللغة العربية. ولكن كيف تقولها؟ كل البدايات صعبة. ",
        title3: "خصائص أخرى للغة العربية",
        title3Description: "الخط العربي هو خط ساكن. لذلك لا يتم تدوين الحروف المتحركة ، وبالتالي يجب التفكير فيها عند القراءة والتحدث ، أو التعرف عليها بشكل أفضل. ولا تنشأ معرفة أحرف العلة المناسبة إلا من خلال التعلم. ومع ذلك ، يتم تسهيل ذلك من خلال حقيقة أن اللغة العربية ، حتى أكثر من الألمانية ، تعتمد على البادئات واللواحق. وهي مرتبطة بكلمة أساسية ثابتة ، ما يسمى الجذر ، وبالتالي تغير معناها. على سبيل المثال ، الكلمات كتابة و  scribe ذات صلة لذلك ، إذا كنت تعرف معنى جذرها ، فيمكنك عادةً تقدير السياق التقريبي للكلمة - حتى لو كانت مصطلحًا جديدًا. نظرًا لتركيبها الخاص ، تقدم اللغة العربية مجموعة متنوعة غير محدودة تقريبًا من التعبيرات تنعكس الاحتمالات أيضًا في العدد الكبير من اللهجات. ",
        title4: "اللغة العربية للوهلة الثانية",
        title4Description: "في المحادثة اليومية ، لا نفكر عادة في أصل الكلمات الفردية. إذا ألقينا نظرة فاحصة على مفرداتنا ، يمكننا أن نجد أن أسلافنا استخدموا بعض الكلمات العربية. من الكحول إلى ذلك صوفا و الزرافة إلى المجلة - كل هذه المصطلحات من أصل عربي. بمجرد أن تبدأ في تعلم اللغة العربية ، سيواجه المتعلمون المزيد من الكلمات ذات الصلة. يمكن لمثل هذه الاكتشافات أن تلهم تقدم التعلم وتوفر رؤى شيقة في تطوير لغتك الخاصة تمكّن. ",
        title5: "تعلم اللغة العربية مفتاح النجاح",
        title5Description: "يمكن قول الكثير في هذه المرحلة ، ولكن أفضل طريقة لتجربة ثراء اللغة العربية هي مجرد البدء في التعلم. في النهاية ، يعتمد التقدم السريع إلى حد كبير على دوافعك الخاصة دائمًا في طريقك ، ومعلمونا إلى جانبك مع النصيحة والعمل. لا يهم ما إذا كنت لا تزال في البداية أو لديك بالفعل معرفة سابقة. ",
        end: "نتطلع إلى استفسارك على:",
        endDescription1: "أو عن طريق الهاتف:",
        endDescription2: "والموبايل",
        email: "info@kaza-sprachdienste.de",
        button: "الرجوع"
    }]
}]

export const thirdFullBlogData = [{
    thirdBlogDe: [{
        title: "Die Aufgaben eines Sprachdienstleisters – deshalb lohnt sich die Zusammenarbeit",
        createdAt: "05.02.2021 von KAZA Sprachdienste",
        imageTitle: "/images/blog/5Feb.jpg",
        text: "Sprache begleitet uns jeden Tag, seit wir als Kinder gelernt haben, Wörter aneinanderzufügen, um uns zu verständigen. Wer mit welcher Sprache aufwächst, hängt vom Geburtsland, der Muttersprache der Eltern und weiteren Faktoren ab. Fast die Hälfte der Weltbevölkerung spricht eine der zehn häufigsten Sprachen als Muttersprache.",
        title2: "Zu den meistgesprochenen Sprachen der Welt zählen aktuell:",
        title2Description: ["Chinesisch","Spanisch","Englisch","Hindi","Arabisch","Portugiesisch","Bengali","Russisch","Japanisch","Punjabi"], 
        title2Description2: "Auch in Deutschland erhalten Fremdsprachen eine immer größere Relevanz. Durch internationale Migration wird heute nahezu jede Sprache der Welt auf deutschen Straßen gesprochen. Zu den häufigsten Sprachen in der Bundesrepublik gehören Russisch, Türkisch, Polnisch, Kurdisch, Italienisch, Griechisch, Arabisch, Niederländisch, Serbisch, Kroatisch, Spanisch und Englisch. Auch auf Geschäftsebene ist Sprachvielfalt nicht mehr wegzudenken: Fremdsprachen spiegeln nicht nur den multikulturellen Wandel der Gesellschaft wider, sondern gelten auch als Schlüssel zum Unternehmenswachstum und als Türöffner zu neuen Märkten. Wer heute unternehmerisch erfolgreich sein möchte, sollte mehrsprachig agieren können.",
        title3: "Womit unterstützt ein Sprachdienstleister seine Kunden?",
        title3Text1: "Niemand erwartet, dass jeder die zehn Weltsprachen aus dem Effeff beherrscht. Sprachenlernen ist ein Prozess, der bei den einen mehr, bei den anderen weniger Zeit in Anspruch nimmt. Deshalb wird gern auf das Angebot externer Sprachdienstleister zurückgegriffen.",
        title3Text2: "Hier werden die meistgesprochenen Sprachen durch Muttersprachler abgedeckt und das Know-how für verschiedene Sprachdienstleistungen zur Verfügung gestellt. Übersetzungen aller Art werden von Experten übernommen – denn die Übertragung eines Textes von einer Sprache in eine andere erfordert weitreichende sprachliche Fähigkeiten. Eine hohe Übersetzungsqualität zeichnet sich dabei durch genaue Terminologie, korrekte Verwendung des Fachjargons und einen flüssigen, grammatikalisch einwandfreien Schreibstil aus, der auf die Zielgruppe zugeschnitten ist. Dabei sind auch kulturelle Unterschiede sowie sprachliche Feinheiten der Ausgangs- und Zielsprache zu berücksichtigen. In der Übersetzungsbranche wird dieser Prozess „Lokalisierung“ genannt. Versierte Sprachdienstleister übersetzen Urkunden, Dokumente und Artikel jeder Art fristgerecht und professionell für den weiteren unternehmerischen oder privaten Gebrauch.  in die gewünschten Sprachen.",
        titleText3: "Auch Tagungen, Konferenzen, Interviews, Symposien, Gerichtsverhandlungen oder Ähnliches können durch professionelle externe Dolmetscher begleitet werden. Diese werden immer dann hinzugezogen, wenn nicht alle Anwesenden über die erforderlichen Sprachkenntnisse für eine reibungslose Verständigung verfügen. Sie muss jedoch sichergestellt sein, damit beispielsweise eine Verhandlung oder eine Tagung ordnungsgemäß, fair und erfolgversprechend ablaufen kann. Ein guter Dolmetscher versteht Aussagen sofort und kann sie in gewissem Maße sogar voraussagen. Nur dann ist die direkte und zeitgleiche Übersetzung – Simultandolmetschen – gewährleistet. Externe Dolmetscher helfen somit, Sprachbarrieren zu durchbrechen.",
        title4: "Mit Sprachdienstleistern Hürden überwinden",
        title4Description: "Es ist also nicht notwendig, alle Sprachen fließend zu beherrschen oder zu erlernen – Sprachdienstleister überwinden verbalen Hürden spielend. Unsere Experten bieten punktgenaue Sprachdienste in über 30 verschiedene Sprachen an. Das verschafft Unterstützung bei individuellen Herausforderungen und verspricht Erfolg in jedem Geschäftsfeld. Überzeugen Sie sich selbst und umgehen Sie Sprachbarrieren mit unserer Unterstützung mühelos.",
        end: "Wir freuen uns auf Ihre Anfrage unter:",
        endDescription1: "oder telefonisch: ",
        endDescription2: "sowie Mobil: ",
        email: "info@kaza-sprachdienste.de",
        button: "Zurück"
    }],
    thirdBlogEn: [{
        title: "The tasks of a language service provider - that's why the cooperation is worthwhile",
        createdAt: "05/02/2021 by KAZA Sprachdienste",
        imageTitle: "/images/blog/5Feb.jpg",
        text: "Language has been with us every day since we learned to put words together as children to communicate. Who grows up with which language depends on the country of birth, the mother tongue of the parents and other factors. Almost half of the world's population speaks one of the ten most common languages as their mother tongue.",
        title2: "The most spoken languages in the world currently include:",
        title2Description: ["Chinese","Spanish","English","Hindi","Arabic","Portuguese","Bengali","Russian","Japanese","Punjabi"],
        title2Description2: "Foreign languages are also becoming increasingly relevant in Germany. Due to international migration, almost every language in the world is spoken on German streets today. The most common languages in Germany include Russian, Turkish, Polish, Kurdish, Italian, Greek, Arabic, Dutch, Serbian, Croatian, Spanish and English. Language diversity is also indispensable at the business level: foreign languages not only reflect the multicultural change in society, but are also considered the key to company growth and a door opener to new markets. If you want to be successful in business today, you should be able to work in several languages.",
        title3: "How does a language service provider support its customers?",
        title3Text1: "Nobody expects everyone to know the ten world languages inside out. Language learning is a process that takes more time for some and less time for others. That is why external language service providers are often used.",
        title3Text2: "Hier werden die meistgesprochenen Sprachen durch Muttersprachler abgedeckt und das Know-how für verschiedene Sprachdienstleistungen zur Verfügung gestellt. Übersetzungen aller Art werden von Experten übernommen – denn die Übertragung eines Textes von einer Sprache in eine andere erfordert weitreichende sprachliche Fähigkeiten. Eine hohe Übersetzungsqualität zeichnet sich dabei durch genaue Terminologie, korrekte Verwendung des Fachjargons und einen flüssigen, grammatikalisch einwandfreien Schreibstil aus, der auf die Zielgruppe zugeschnitten ist. Dabei sind auch kulturelle Unterschiede sowie sprachliche Feinheiten der Ausgangs- und Zielsprache zu berücksichtigen. In der Übersetzungsbranche wird dieser Prozess „Lokalisierung“ genannt. Versierte Sprachdienstleister übersetzen Urkunden, Dokumente und Artikel jeder Art fristgerecht und professionell für den weiteren unternehmerischen oder privaten Gebrauch.  in die gewünschten Sprachen.",
        title3Text3: "Meetings, conferences, interviews, symposiums, court hearings or similar can also be accompanied by professional external interpreters. These are always called in when not everyone present has the language skills required for smooth communication. However, it must be ensured so that, for example, a negotiation or a conference can take place properly, fairly and with good prospects of success. A good interpreter understands what is being said immediately and can even predict it to a certain extent. Only then is the direct and simultaneous translation – simultaneous interpreting – guaranteed. External interpreters thus help to break down language barriers.",
        title4: "Overcoming hurdles with language service providers",
        title4Description: "It is therefore not necessary to be fluent in or to learn all languages - language service providers easily overcome verbal hurdles. Our experts provide pinpoint language services in over 30 different languages. This provides support for individual challenges and promises success in every business area. Convince yourself and easily bypass language barriers with our support.",
        end: "We look forward to your inquiry at:",
        endDescription1: "or by phone: ",
        endDescription2: "and mobile: ",
        email: "info@kaza-sprachdienste.de",
        button: "Back"
    }],
    thirdBlogAr: [{
        title: "مهام مزود خدمة اللغة - لهذا التعاون جدير بالاهتمام",
        createdAt: "05/02/2021 بواسطة KAZA Sprachdienste",
        imageTitle: "/images/blog/5Feb.jpg",
        text: "كانت اللغة معنا كل يوم منذ أن تعلمنا أن نجمع الكلمات معًا كأطفال للتواصل. من يكبر مع أي لغة تعتمد على بلد الميلاد واللغة الأم للوالدين وعوامل أخرى. يتحدث نصف سكان العالم تقريبًا إحدى اللغات العشر الأكثر شيوعًا كلغتهم الأم.",
        title2: "تشمل اللغات الأكثر استخدامًا في العالم حاليًا ما يلي:",
        title2Description: ["الصينية", "الإسبانية", "الإنجليزية", "الهندية", "العربية", "البرتغالية", "البنغالية", "الروسية" ,"اليابانية", "البنجابية"],
        title2Description2: "أصبحت اللغات الأجنبية ذات أهمية متزايدة في ألمانيا. بسبب الهجرة الدولية ، يتم التحدث بكل لغة في العالم تقريبًا في شوارع ألمانيا اليوم. تشمل اللغات الأكثر شيوعًا في ألمانيا الروسية والتركية والبولندية والكردية والإيطالية واليونانية والعربية والهولندية والصربية والكرواتية والإسبانية والإنجليزية. يعد التنوع اللغوي أيضًا أمرًا لا غنى عنه على مستوى الأعمال: فاللغات الأجنبية لا تعكس فقط التغيير متعدد الثقافات في المجتمع ، ولكنها تعتبر أيضًا مفتاح نمو الشركة وفتح الباب لأسواق جديدة. إذا كنت تريد أن تكون ناجحًا في مجال الأعمال اليوم ، فيجب أن تكون قادرًا على العمل بعدة لغات.",
        title3: "كيف يدعم مقدم الخدمة اللغوية عملائه؟",
        title3Text1: "لا أحد يتوقع من الجميع معرفة لغات العالم العشر من الداخل إلى الخارج. تعلم اللغة عملية تستغرق وقتًا أطول للبعض ووقتًا أقل للآخرين. هذا هو السبب في كثير من الأحيان يتم استخدام موفري خدمات اللغة الخارجية.",
        title3Text2: "هنا ، يتم تغطية اللغات الأكثر شيوعًا من قبل متحدثين أصليين ويتم توفير المعرفة الفنية للخدمات اللغوية المختلفة. تتم الترجمات بجميع أنواعها بواسطة خبراء - لأن نقل النص من لغة إلى أخرى يتطلب مهارات لغوية واسعة النطاق. تتميز جودة الترجمة العالية بمصطلحات دقيقة واستخدام صحيح للمصطلحات الفنية وأسلوب كتابة سليم نحويًا بطلاقة مصمم خصيصًا للمجموعة المستهدفة. يجب أيضًا مراعاة الاختلافات الثقافية واللغوية الدقيقة للغات المصدر والهدف. في صناعة الترجمة ، تسمى هذه العملية التوطين. يقوم مقدمو الخدمات اللغوية ذوو الخبرة بترجمة الشهادات والوثائق والمقالات من جميع الأنواع في الوقت المناسب وبطريقة احترافية لمزيد من الاستخدام المؤسسي أو الخاص. باللغات المطلوبة.",
        title3Text3: "يمكن أيضًا أن يرافق الاجتماعات والمؤتمرات والمقابلات والندوات وجلسات الاستماع في المحاكم أو ما شابه ذلك مترجمون خارجيون محترفون. يتم استدعاؤها دائمًا عندما لا يكون لدى جميع الحاضرين المهارات اللغوية المطلوبة للتواصل السلس. ومع ذلك ، يجب ضمان ذلك ، على سبيل المثال ، يمكن إجراء مفاوضات أو مؤتمر بشكل صحيح وعادل وباحتمالات نجاح جيدة. يفهم المترجم الجيد ما يقال على الفور ويمكنه حتى التنبؤ به إلى حد معين. عندها فقط تكون الترجمة المباشرة والمتزامنة - الترجمة الفورية - مضمونة. وبالتالي فإن المترجمين الخارجيين يساعدون في كسر الحواجز اللغوية.",
        title4: "التغلب على العقبات مع مقدمي الخدمات اللغوية",
        title4Description: "لذلك ليس من الضروري أن تتقن كل اللغات أو تتعلمها - يتغلب مقدمو خدمات اللغة بسهولة على العقبات اللفظية. يقدم خبراؤنا خدمات لغوية محددة بأكثر من 30 لغة مختلفة. يوفر هذا الدعم للتحديات الفردية ويعد بالنجاح في كل مجال عمل. أقنع نفسك وتجاوز حواجز اللغة بسهولة بدعمنا.",
        end: "نتطلع إلى استفسارك على:",
        endDescription1: "أو عن طريق الهاتف:",
        endDescription2: "والموبايل",
        email: "info@kaza-sprachdienste.de",
        button: "الرجوع"
    }]
}]