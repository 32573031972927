import React, { useContext, useEffect } from 'react';
import { DataStore } from "../Store/Context";
import TeamComponent from "../components/team/TeamComponent";

function TeamPage() {

    const { teamData, lan } = useContext(DataStore);

    const Team = teamData.map(item => item)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
      }, []);

    return (
        <div className="container mb-5">
        {lan && lan === "Ar" ? <div className="row mt-5 mb-5 text-center"><h3>فريقنا</h3></div> : <div className="row mt-5 mb-5 text-center"><h3>Team</h3></div>}


        <TeamComponent Team={Team}/>

        </div>
    )
}

export default TeamPage
