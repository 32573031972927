import React, { useEffect } from 'react'
import BlogComponent from '../components/blog/BlogComponent'

function BlogPage() {


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
      }, []);

    return (
        <div>
           <BlogComponent />
        </div>
    )
}

export default BlogPage
