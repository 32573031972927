import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataStore } from "../Store/Context";

function TranslatePage() {

    const { data, lan } = useContext(DataStore);

    const { title, titleImage, title1, title1Description, title2, title2Description1, title2Description2, title3, title3Description1, title3Description2, title3Description3, title4, title4Description1, title4Description2, title4Description3, title4Description4, contactTitle, contactPersonEmail, contactPersonTel } = data[0].translationPageData[0];
    const { offerText1, offerText2, offerButton, email, phone } = data[0].homePageData[0];

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, []);

    return (
        <div className="container px-3">
            {/* page Title */}
            <div className="row mt-5 mb-5 text-center">
                <h3>{title}</h3>
            </div>

            {/* first title and description */}
            <div className="row mb-4">
                <div className="col-lg-8">
                    <h5>{title1}</h5>
                    <p>{title1Description}</p>
                </div>
                <div className="col-lg-4">
                    <img src={titleImage} alt="" className="img-fluid rounded" />
                </div>
            </div>

            {/* second title and description */}
            <div className="row mb-4">
                <h5>{title2}</h5>
                <p className="mb-0">{title2Description1}</p>
                <p>{title2Description2}</p>
            </div>

            {/* third title and description*/}
            <div className="row mb-4">
                <h5>{title3}</h5>
                <p className="mb-0">{title3Description1}</p>
                <p className="mb-0">{title3Description2}</p>
                <p>{title3Description3}</p>
            </div>

            {/* forth title and description*/}
            <div className="row mb-4">
                <h5>{title4}</h5>
                <p>{title4Description1}</p>
                <p>{title4Description2}</p>
                <p>{title4Description3}</p>
                <p>{title4Description4}</p>
            </div>

            {/* contact person */}
            <div className="row mt-5 d-flex align-items-center">
                <div className="row mb-1"><h5>{contactTitle}</h5></div>
                {lan && lan === "Ar" ? <div className="col-lg-4 col-sm-12 mb-5" >
                    <h6 className="mb-0"> عن طريق الإيميل: <a className="text-decoration-none" href={`mailto:${contactPersonEmail}`}>{contactPersonEmail}</a></h6>
                    <h6 > عن طريق الهاتف: <a className="text-decoration-none" href={`tel:${contactPersonTel}`}>{contactPersonTel}</a> </h6>
                </div> : <div className="col-lg-4 col-sm-12 mb-5" style={{ direction: "ltr" }}>
                    <p className="mb-0">{email} <a className="text-decoration-none" href={`mailto:${contactPersonEmail}`}>{contactPersonEmail}</a></p>
                    <p >{phone} <a className="text-decoration-none" href={`tel:${contactPersonTel}`}>{contactPersonTel}</a></p>
                </div>}


            </div>

            {/* wisdom */}
            <div className="row text-center mb-5 pt-5">
                <h5>{offerText1}</h5>
                <h5>{offerText2}</h5>
            </div>
            <div className="row text-center mt-5 mb-5 d-flex justify-content-center">
                <div className="col"><Link to="/requestforquote" className="btn btn-primary btn-lg">{offerButton}</Link></div>
            </div>
        </div>
    )
}

export default TranslatePage
