import React, { useState, useContext, useEffect } from 'react';
import { DataStore } from "../../Store/Context";
import axios from '../../util/Axios';

/* the checkmark animation by sending the request */
import { motion, useMotionValue } from "framer-motion";
import CircularProgress from './CircularProgress';

function RequestForQuoteFamily() {


    /* the checkmark animation by sending the request */
    let progress = useMotionValue(90);

    const { data, lan } = useContext(DataStore);

    const { title_, greeting_, Mrs_, Mr_, company_, firstName_, lastName_, email_, phone_, city_, street_, hausNumber_, plz_, services_, serviceFamily, message, privacyPolicy, privacyPolicyDescription, sendButton, } = data[0].offerPageData[0];

    const [greeting, setGreeting] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [hausNumber, sethausNumber] = useState("");
    const [plz, setPlz] = useState("");
    const [servicesTitle, setServicesTitle] = useState(serviceFamily);
    const [wakalah, setWakalah] = useState(false);
    const [wakalahWithAuthen, setWakalahWithAuthen] = useState(false);
    const [sendWakalah, setSendWakalah] = useState(false);
    const [weddingInstall, setWeddingInstall] = useState(false);
    const [embassyAppointment, setEmbassyAppointment] = useState(false);
    const [fillOutForm, setFillOutForm] = useState(false);
    const [travelOrganizationVIP, setTravelOrganizationVIP] = useState(false);
    const [beforeExamOrganization, setBeforeExamOrganization] = useState(false);
    const [travelOrganizationGoethe, setTravelOrganizationGoethe] = useState(false);
    const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState(false)
    const [text, setText] = useState("")

    /* getting message from server */
    const [res, setRes] = useState();

    /* reset all options */
    const resetOptions = () => {
        setWakalah(false)
        setWakalahWithAuthen(false)
        setSendWakalah(false)
        setWeddingInstall(false)
        setEmbassyAppointment(false)
        setFillOutForm(false)
        setTravelOrganizationVIP(false)
        setBeforeExamOrganization(false)
        setTravelOrganizationGoethe(false)
        setAgreePrivacyPolicy(false)
    }

    const handelSentEmail = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post("/send/FamilyReunionRequest", {

                text: text,
                greeting: greeting,
                lastName: lastName,
                firstName: firstName,
                userEmail: userEmail,
                userPhone: userPhone,
                city: city,
                street: street,
                hausNumber: hausNumber,
                plz: plz,
                servicesTitle: servicesTitle,
                wakalah: wakalah,
                wakalahWithAuthen: wakalahWithAuthen,
                sendWakalah: sendWakalah,
                weddingInstall: weddingInstall,
                embassyAppointment: embassyAppointment,
                fillOutForm: fillOutForm,
                travelOrganizationVIP: travelOrganizationVIP,
                beforeExamOrganization: beforeExamOrganization,
                travelOrganizationGoethe: travelOrganizationGoethe,
                privacyPolicy: agreePrivacyPolicy
            });

            e.target.reset();
            resetOptions()
            return setRes(response.data.message)

        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
        setTimeout(() => {
            setRes()
        }, 3000);
    }, [servicesTitle, res]);

    return (
        <div className="container">
            {/* page Title */}
            <div className="row mt-5 mb-5 text-center">
                <h3>{lan && lan === "Ar" ? title_ + " لـ خدمات لم الشمل" : lan && lan === "En" ? title_ + " for Family reunification" : title_ + " für Familienzusammenführung"}</h3>
            </div>

            {res && res ? <div className="row text-center">
            {/* the checkmark animation by sending the request */}
            <motion.div
                initial={{ x: 0 }}
                animate={{ x: 100 }}
                style={{ x: progress }}
                transition={{ duration: 3 }}
            />
            <CircularProgress progress={progress} />
        </div> : ""}
        
            <form onSubmit={handelSentEmail} className="my-5">
                {/* custumer information */}

                <div className="row mb-3">
                    <div className="form-check">
                        <label htmlFor="greeting" className="me-5">*{greeting_}</label>

                        <div className="form-check form-check-inline">
                            <input type="radio" id="mr" name="greeting" className="form-check-input" onChange={(e) => setGreeting(e.target.value)} value="Mr." style={{ cursor: 'pointer' }} required />
                            <label htmlFor="mr" className="form-check-label" style={{ cursor: 'pointer' }}>{Mr_}</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input type="radio" id="mrs" name="greeting" className="form-check-input" onChange={(e) => setGreeting(e.target.value)} value="Mrs." style={{ cursor: 'pointer' }} />
                            <label htmlFor="mrs" className="form-check-label" style={{ cursor: 'pointer' }}>{Mrs_}</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input type="radio" id="company" name="greeting" className="form-check-input" onChange={(e) => setGreeting(e.target.value)} value="Company" style={{ cursor: 'pointer' }} />
                            <label htmlFor="company" className="form-check-label" style={{ cursor: 'pointer' }}>{company_}</label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-12">
                        <div className="mb-3">
                            <input type="text" name="lastName" className="form-control" placeholder={`*${lastName_}`} onChange={(e) => setLastName(e.target.value)} required />
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-12">
                        <div className="mb-3">
                            <input type="text" name="firstName" className="form-control" placeholder={`*${firstName_}`} onChange={(e) => setFirstName(e.target.value)} required />
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-12">
                        <div className="mb-3">
                            <input type="email" name="userEmail" className="form-control" placeholder={`*${email_}`} onChange={(e) => setUserEmail(e.target.value)} required />
                        </div>
                    </div>
                </div>

                {/* custumer information */}
                <div className="row">
                    <div className="col-lg-4 col-sm-12">
                        <div className="mb-3">
                            <input type="text" name="userPhone" className="form-control myStyle" placeholder={`*${phone_}`} onChange={(e) => setUserPhone(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-12">
                        <div className="mb-3">
                            <input type="text" name="city" className="form-control" placeholder={city_} onChange={(e) => setCity(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-12">
                        <div className="mb-3">
                            <input type="text" name="street" className="form-control" placeholder={street_} onChange={(e) => setStreet(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-12">
                        <div className="mb-3">
                            <input type="text" name="hausNumber" className="form-control" placeholder={hausNumber_} onChange={(e) => sethausNumber(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-12">
                        <div className="mb-3">
                            <input type="text" name="plz" className="form-control" placeholder={plz_} onChange={(e) => setPlz(e.target.value)} />
                        </div>
                    </div>
                </div>

                {/* custumer services */}
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="input-group mb-3">
                            <label htmlFor="servicesTitle" className="input-group-text">{services_}</label>
                            <select name="servicesTitle" className="form-select" onChange={(e) => setServicesTitle(e.target.value)} style={{ cursor: 'pointer' }} required>
                                <option value={serviceFamily}>{serviceFamily}</option>
                            </select>
                        </div>
                    </div>

                </div>

                {/* service Options */}
                {lan && lan === "Ar" ?
                    <div className="row mb-3">
                        <div className="col-lg-3 col-sm-12 pt-2 d-flex">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="wakalah" name="wakalah" onChange={() => setWakalah(!wakalah)} checked={wakalah} style={{ cursor: 'pointer' }} />
                                <label htmlFor="wakalah" className="form-check-label" style={{ cursor: 'pointer' }}>صياغة الوكالة مع الترجمة</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 pt-2 d-flex">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="wakalahWithAuthen" name="wakalahWithAuthen" onChange={() => setWakalahWithAuthen(!wakalahWithAuthen)} checked={wakalahWithAuthen} style={{ cursor: 'pointer' }} />
                                <label htmlFor="wakalahWithAuthen" className="form-check-label" style={{ cursor: 'pointer' }}>صياغة الوكالة مع الترجمة والتصديق</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 pt-2 d-flex">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="sendWakalah" name="sendWakalah" onChange={() => setSendWakalah(!sendWakalah)} checked={sendWakalah} style={{ cursor: 'pointer' }} />
                                <label htmlFor="sendWakalah" className="form-check-label" style={{ cursor: 'pointer' }}>إرسال الوكالة إلى سوريا بالبريد المضمون</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 pt-2 d-flex">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="weddingInstall" name="weddingInstall" onChange={() => setWeddingInstall(!weddingInstall)} checked={weddingInstall} style={{ cursor: 'pointer' }} />
                                <label htmlFor="weddingInstall" className="form-check-label" style={{ cursor: 'pointer' }}>إنهاء إجراءات الزواج في سوريا (تثبيت الزواج)</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 pt-2 d-flex">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="embassyAppointment" name="embassyAppointment" onChange={() => setEmbassyAppointment(!embassyAppointment)} checked={embassyAppointment} style={{ cursor: 'pointer' }} />
                                <label htmlFor="embassyAppointment" className="form-check-label" style={{ cursor: 'pointer' }}>حجز موعد لدى السفارة الألمانية في بيروت</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 pt-2 d-flex">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="fillOutForm" name="fillOutForm" onChange={() => setFillOutForm(!fillOutForm)} checked={fillOutForm} style={{ cursor: 'pointer' }} />
                                <label htmlFor="fillOutForm" className="form-check-label" style={{ cursor: 'pointer' }}>ملء إستمارة الفيزا للسفارة الألمانية</label>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-12 pt-2 d-flex">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="travelOrganizationVIP" name="travelOrganizationVIP" onChange={() => setTravelOrganizationVIP(!travelOrganizationVIP)} checked={travelOrganizationVIP} style={{ cursor: 'pointer' }} />
                                <label htmlFor="travelOrganizationVIP" className="form-check-label" style={{ cursor: 'pointer' }}>تنظيم السفر من وإلى بيروت يوم المقابلة (VIP)</label>
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-12 pt-2 d-flex">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="beforeExamOrganization" name="beforeExamOrganization" onChange={() => setBeforeExamOrganization(!beforeExamOrganization)} checked={beforeExamOrganization} style={{ cursor: 'pointer' }} />
                                <label htmlFor="beforeExamOrganization" className="form-check-label" style={{ cursor: 'pointer' }}>تدريب وتأهيل الزوجة من الناحية اللغوية بشكل مكثف قبل الإمتحان</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 pt-2 d-flex">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="travelOrganizationGoethe" name="travelOrganizationGoethe" onChange={() => setTravelOrganizationGoethe(!travelOrganizationGoethe)} checked={travelOrganizationGoethe} style={{ cursor: 'pointer' }} />
                                <label htmlFor="travelOrganizationGoethe" className="form-check-label" style={{ cursor: 'pointer' }}>حجز موعد لإمتحان اللغة في معهد غوته وتنظيم السفر من وإلى بيروت يوم الفحص</label>
                            </div>
                        </div>
                    </div> : ""}

                {/* user message and files */}
                <div className="row mb-3">
                    <div className="col-lg-6 mb-3">
                        <textarea className="form-control" name="text" cols="30" rows="10" maxlength="1500" placeholder={message} onChange={(e) => setText(e.target.value)} style={{ resize: "none" }} required></textarea>
                    </div>
                    {/* input fields
                <div className="col-lg-6">
                        <label htmlFor="files" className="input-text">{attachDocuments}</label>
                        <div className="input-group my-3">
                            <input type="file" name="files" multiple className="form-control dropzone" onChange={(e) => setFiles(e.target.files)} />
                        </div>
                    </div> */}
                </div>

                <div className="row p-4">
                    <hr />
                    <div className="col-12 pb-3 d-flex">
                        <div className="form-check form-switch" >
                            <input className="form-check-input" type="checkbox" role="switch" id="privacyPolicy" name="privacyPolicy" onClick={() => setAgreePrivacyPolicy(!agreePrivacyPolicy)} checked={agreePrivacyPolicy} style={{ cursor: 'pointer' }} />
                            <label className="form-check-label" htmlFor="privacyPolicy" style={{ cursor: 'pointer' }}>*{privacyPolicy}</label>
                        </div>
                    </div>

                    <div className="col-12">
                        <p>{privacyPolicyDescription}</p>
                    </div>

                    <div className="form-group my-3">
                        <button type="submit" className="btn btn-primary btn-lg">
                            {sendButton}
                        </button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>
    )
}

export default RequestForQuoteFamily


