import React, { useEffect, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.scss';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';
import { DataStore } from './Store/Context';

import { AcquisitionOfDocumentPage, HomePage, TranslatePage, InterpretingPage, PrivateLessonsPage, RequestForQuotePage, BlogPage, FamilyReunionPage, TeamPage, VisionPage, BlogFullRead, Impressum, Datenschutz, NotFoundPage } from './pages/index';
import RequestForQuoteFamily from './components/requestComponent/RequestForQuoteFamily';
import RequestForQuotePrivateLesson from './components/requestComponent/RequestForQuotePrivateLesson';
import RequestforQuoteDocument from './components/requestComponent/RequestforQuoteDocument';


function App({ children }) {

  const { lan } = useContext(DataStore);

  useEffect(() => {

  }, [])

  return (
    <div className={lan}>
      <Navbar />

      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/translate" component={TranslatePage} />
        <Route exact path="/interpreting" component={InterpretingPage} />
        <Route exact path="/privatelesson" component={PrivateLessonsPage} />
        <Route exact path="/requestforquote" component={RequestForQuotePage} />
        <Route exact path="/blog" component={BlogPage} />
        <Route exact path="/acquisitionofdocument" component={AcquisitionOfDocumentPage} />
        <Route exact path="/familyreunion" component={FamilyReunionPage} />
        <Route exact path="/team" component={TeamPage} />
        <Route exact path="/vision" component={VisionPage} />
        <Route exact path={`/language blog detail view/:blogLink`} component={BlogFullRead} />
        <Route exact path="/impressum" component={Impressum} />
        <Route exact path="/datenschutz" component={Datenschutz} />
        <Route exact path="/requestforquotefamily" component={RequestForQuoteFamily} />
        <Route exact path="/requestforquotelesson" component={RequestForQuotePrivateLesson} />
        <Route exact path="/requestforquotedocument" component={RequestforQuoteDocument} />
        <Route exact component={NotFoundPage} />

      </Switch>

      {children}
      <Footer />
    </div>
  );
}

export default App;
