import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataStore } from "../Store/Context";

function PrivateLessonsPage() {

    const { data, lan } = useContext(DataStore);

    const { title, title1, title1Description1, title1Description2, title1Description3, title1Description4, title1Description5, title1Description6, title2, title2Description1, title2Description2, title2Description3, title2Description4, title2Description5, title2Description6, title2Description7, title2Description8, title2Description9, title2Description10, title2Description11, title2Description12, text1, text2, text3, text4, title3, title4, title4Description, title5, title5Description, title6, title6Description, contactTitle, contactPersonEmail, contactPersonTel } = data[0].tutoringPageData[0];
    const { offerText1, offerText2, offerButton, email, phone } = data[0].homePageData[0];

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
      }, []);

    return (
        <div className="container px-3">
            {/* page Title */}
            <div className="row mt-5 mb-5 text-center">
                <h3>{title}</h3>
            </div>

            {/* first section */}
            <div className="row mb-4">
                <div className="col-lg-6">
                    <h5>{title1}</h5>
                    <ul className="px-5">
                        <li>{title1Description1}</li>
                        <li>{title1Description2}</li>
                        <li>{title1Description3}</li>
                        <li>{title1Description4}</li>
                        <li>{title1Description5}</li>
                        <li>{title1Description6}</li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <h5>{title2}</h5>
                    {lan !== "Ar" && <p className="mb-0">{title2Description1}</p>}
                    <ul className="px-5">
                        {lan && lan === "Ar" && <li>{title2Description1}</li>}
                        <li>{title2Description2}</li>
                        <li>{title2Description3}</li>
                        <li>{title2Description4}</li>
                        <li>{title2Description5}</li>
                        <li>{title2Description6}</li>
                        <li>{title2Description7}</li>
                        <li>{title2Description8}</li>
                        <li>{title2Description9}</li>
                        {lan && lan === "Ar" ? <li>{title2Description10}</li> : ""}
                        <li>{title2Description11}</li>
                        <li>{title2Description12}</li>
                    </ul>
                </div>
            </div>

            {/* second section */}
            <div className="row mb-4">
                <p className="mb-0">{text1}</p>
                <p className="mb-0">{text2}</p>
                <p className="mb-0">{text3}</p>
                <p>{text4}</p>
            </div>

            {/* third section */}
            <div className="row mb-4">
                <h5>{title3}</h5>
                <p className="mb-0">{title4}</p>
                <p>{title4Description}</p>
                <p className="mb-0">{title5}</p>
                <p >{title5Description}</p>
                <p className="mb-0">{title6}</p>
                <p>{title6Description}</p>
            </div>



            {/* contact person */}
            <div className="row mt-5 d-flex align-items-center">
                <div className="row mb-1"><h5>{contactTitle}</h5></div>
                {lan && lan === "Ar" ? <div className="col-lg-4 col-sm-12 mb-5" >
                <h6 className="mb-0"> عن طريق الإيميل: <a className="text-decoration-none" href={`mailto:${contactPersonEmail}`}>{contactPersonEmail}</a></h6>
                <h6 > عن طريق الهاتف: <a className="text-decoration-none" href={`tel:${contactPersonTel}`}>{contactPersonTel}</a> </h6>
            </div> : <div className="col-lg-4 col-sm-12 mb-5" style={{ direction: "ltr"}}>
                    <p className="mb-0">{email} <a className="text-decoration-none" href={`mailto:${contactPersonEmail}`}>{contactPersonEmail}</a></p>
                    <p >{phone} <a className="text-decoration-none" href={`tel:${contactPersonTel}`}>{contactPersonTel}</a></p>
                </div>}
                
                
            </div>

            {/* wisdom */}
            <div className="row text-center mb-5 pt-5">
                <h5>{offerText1}</h5>
                <h5>{offerText2}</h5>
            </div>
            <div className="row text-center mt-5 mb-5 d-flex justify-content-center">
                <div className="col"><Link to="/requestforquotelesson" className="btn btn-primary btn-lg">{offerButton}</Link></div>
            </div>
        </div>
    )
}

export default PrivateLessonsPage
