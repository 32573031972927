import React, { useEffect } from 'react';

import RequestForQueoteTranslateAndInterpreting from '../components/requestComponent/RequestForQueoteTranslateAndInterpreting';

function RequestForQuotePage() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, []);

    return (
        <div className="container">
        <RequestForQueoteTranslateAndInterpreting />
        </div>
    )
}

export default RequestForQuotePage


