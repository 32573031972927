import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DataStore } from '../../Store/Context';
import './Navbar.style.scss';

function Navbar() {

    const { data, changeLanguage, lan } = useContext(DataStore);

    const { start, translation, interpreting, family, tutoring, offer, blog, other, logo, about, document, team,
        vision } = data[0].navbarData[0];
        
    return (
        <nav className="navbar navbar-expand-lg navbar-light container my-nav-style">
            <div className="container-fluid my-container-fluid">
                {/* KAZA Logo */}
                <Link className="navbar-brand" to="/"><img src={logo} alt="logo" style={{ width: "100px" }} /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <div className="mx-5"></div>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            {/* home page */}

                            <Link className="nav-link active" aria-current="page" to="/">{start}</Link>
                        </li>
                        <li className="nav-item">
                            {/* translate page */}

                            <Link className="nav-link" to="/translate" >{translation}</Link>
                        </li>
                        <li className="nav-item">
                            {/* interpreting page */}

                            <Link className="nav-link" to="/interpreting">{interpreting}</Link>
                        </li>
                        <li className="nav-item">
                            {/* private lesson page  */}

                            <Link className="nav-link" to="/privatelesson">{tutoring}</Link>
                        </li>
                        <li className="nav-item dropdown">
                            {/* extra services menu */}

                            <Link className="nav-link dropdown-toggle" to="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {other}
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li><Link className="dropdown-item d-flex justify-content-start" to="/familyreunion">{family}</Link></li>
                                <li><Link className="dropdown-item d-flex justify-content-start" to="/acquisitionofdocument">{document}</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            {/* request for quote page */}

                            <Link className="nav-link" to="/requestforquote">{offer}</Link>
                        </li>
                        <li className="nav-item">
                            {/* blog page*/}

                            <Link className="nav-link" to="/blog">{blog}</Link>
                        </li>
                        <li className="nav-item dropdown">
                            {/* about us menu */}

                            <Link className="nav-link dropdown-toggle" to="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {about}
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li><Link className="dropdown-item d-flex justify-content-start" to="/team">{team}</Link></li>
                                <li><Link className="dropdown-item d-flex justify-content-start" to="/vision">{vision}</Link></li>
                            </ul>
                        </li>
                    </ul>
                    <div className={lan === "Ar" ? "mx-5" : ""} >
                        {/* KAZA Logo */}

                        <select onChange={changeLanguage} defaultValue={localStorage.getItem("lan")} name="choose" className="flex-end">
                            <option value="De">De</option>
                            <option value="En">En</option>
                            <option value="Ar">Ar</option>
                        </select>
                    </div>
                </div>

            </div>

        </nav>
    )
}

export default Navbar
