import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

function TeamComponent({ Team }) {


    useEffect(() => {
        AOS.init({ duration: 3000 });
    }, []);

    return <div className="row">{Team && Team.map(elem => {
        return (
            <div key={elem.personName} data-aos="fade-up" className="col-lg-6 mb-5" >

                <div className="row p-3" >
                    <div className="col-lg-6 text-center">
                        <img src={elem.personImage} className="img-fluid mb-3" style={{ maxHeight: "350px" }} alt="" />
                    </div>
                    <div className="col-lg-6"><p>{elem.personDescription}</p></div>
                </div>
                <div className="row p-3 mt-2">
                    <h3>{elem.personName}</h3>
                    <h5 className="pb-3">{elem.personRole}</h5>

                    <p>{elem.personText1}</p>
                    <p>{elem.personText2}</p>
                    <p>{elem.personText3}</p>
                    <p>{elem.personText4}</p>
                    <p>{elem.personText5}</p>
                    <p>{elem.personText6}</p>
                    <p>{elem.personText7}</p>
                </div>
            </div>)
    })}</div>
}

export default TeamComponent;
