import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DataStore } from "../../Store/Context";
import FirstBlog from './FirstBlog';
import SecondBlog from './SecondBlog';
import ThirdBlog from './ThirdBlog';


function BlogFullRead() {

  const { blogLink } = useParams();
  const { blogData, firstBlogData, secondBlogData, thirdBlogData, lan } = useContext(DataStore);

  const selectedBlog = blogData.filter(item => item.blogLink === blogLink);

  const send = selectedBlog.map(item => item.blogLink).toString();

  return (
    <div>
      {send === "first blog" ? <FirstBlog data={firstBlogData} lan={lan} /> : send && send === "second blog" ? <SecondBlog data={secondBlogData}/> : <ThirdBlog data={thirdBlogData}/>}
    </div>)
}

export default BlogFullRead;
