import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { DataStore } from "../../Store/Context";
import "./Footer.style.scss";

function Footer() {

  const { data } = useContext(DataStore);

  const { socialMedia } = data[0].homePageData[0];

  return (


    <div className="container-fluid container1 pt-5">

      <div className="container-fluid pt-5">
        <div className="row justify-content-center align-items-center text-center py-4">
          {/* left section */}
          <div className="address col-lg-3">
            <h5>KAZA Sprachdienste</h5>
            <p>Hans-Poeche-Straße 5</p>
            <p>04103 Leipzig </p>
            <p>Phone: <a className="text-decoration-none " href={"tel:0341 / 860 872 73"}>0341 / 860 872 73</a></p>
            <p>E-mail: <a className="text-decoration-none " href={"mailto:info@kaza-sprachdienste.de"}>info@kaza-sprachdienste.de</a></p>
          </div>

          {/* middle section */}
          <div className="map col-lg-6 col-md-8 col-sm-4">
            <iframe
              title="kaza-location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2335.37687867386!2d12.389783587836199!3d51.3446875069716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a6f993b591bb3d%3A0xbfa1072bbf62d1d9!2sKAZA%20Sprachdienste!5e0!3m2!1sen!2sde!4v1647096726434!5m2!1sen!2sde"
              width="100%"
              height="100%"
              style={{ border: "0", borderRadius: "8px" }}
              loading="lazy"
            ></iframe>
          </div>

          {/* right section */}
          <div className="socialMedia col-lg-3 ">
            <h5 className="mt-3">{socialMedia}</h5>

            <div className="my-5 socialMediaCol"><a href="https://de-de.facebook.com/KAZASprachdienste/services" className="text-decoration-none px-2" target="_blank" rel="noreferrer">
              Facebook</a>
              <a href="https://www.instagram.com/kazasprachdienste" className="text-decoration-none px-2" target="_blank" rel="noreferrer">
                Instagram</a>
            </div>
            <div className="socialMediaCol">
              <a href="https://www.linkedin.com/company/kaza-sprachdienste/" className="text-decoration-none px-2" target="_blank" rel="noreferrer">
                LinkedIn</a>
              <a href="https://www.google.de/maps/place/KAZA+Sprachdienste/@51.3439179,12.3902238,20.72z/data=!4m5!3m4!1s0x47a6f993b591bb3d:0xbfa1072bbf62d1d9!8m2!3d51.343968!4d12.390145?hl=en" className="text-decoration-none px-2" target="_blank" rel="noreferrer">Google</a>
            </div>
          </div>
        </div>

        <hr />

        {/* last section Datenschutz Impresum developed*/}
        <div className="copyRight row d-flex ">
          <div className="link col-lg-4 col-sm-12 px-4 text-center py-2">
            <Link to="/datenschutz" className="text-decoration-none px-3">Datenschutz</Link>
            <Link to="/impressum" className="text-decoration-none px-3">Impressum</Link>
          </div>

          <div className="designed col-lg-4 col-sm-12 px-4 text-center py-2 m-0">
          <p>KAZA Sprachdienste © 2022</p>
          </div>

          <div className="col-lg-4 col-sm-12 d-flex justify-content-center align-items-center">
            <p onClick={() => window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth"
            })} className="text-primary btn">Top</p>
          </div>

        </div>
      </div>


    </div>


  );
}

export default Footer;
