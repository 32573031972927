import React, { createContext, useState, useEffect } from 'react';
import { webDataSourceDE, webDataSourceEN, webDataSourceAR } from '../Data/DataStore';
import { TeamDataDe, TeamDataEn, TeamDataAr } from '../Data/TeamData';
import { blogDataDe, blogDataEn, blogDataAr, firstFullBlogData, secondFullBlogData, thirdFullBlogData } from '../Data/BlogData';

export const DataStore = createContext();

export function WebData({ children }) {

    const lan = localStorage.getItem('lan');
    // Web Content State
    const [data, setData] = useState(() => {
        if(lan === "De"){
           return webDataSourceDE
        } else if(lan === "En"){
           return webDataSourceEN
        } else if(lan === "Ar"){
           return webDataSourceAR
        } else {
            return webDataSourceDE
        }
    })
    
    // team data state
    const [teamData, setTeamData] = useState(() => {
        if(lan === "De"){
           return TeamDataDe
        } else if(lan === "En"){
           return TeamDataEn
        } else if(lan === "Ar"){
           return TeamDataAr
        } else {
            return TeamDataDe
        }
    })

    // blog data state
    const [firstBlogData, setFirstBlogData] = useState(()=>{
        if(lan === "De"){
          return firstFullBlogData[0].firstBlogDe
        }else if(lan === "En"){
          return firstFullBlogData[0].firstBlogEn
        } else if(lan === "Ar"){
          return firstFullBlogData[0].firstBlogAr
        } else {
          return firstFullBlogData[0].firstBlogDe
        }
      })
    const [secondBlogData, setSecondBlogData] = useState(()=>{
        if(lan === "De"){
          return secondFullBlogData[0].secondBlogDe
        }else if(lan === "En"){
          return secondFullBlogData[0].secondBlogEn
        } else if(lan === "Ar"){
          return secondFullBlogData[0].secondBlogAr
        } else {
          return secondFullBlogData[0].secondBlogDe
        }
      })
    const [thirdBlogData, setThirdBlogData] = useState(()=>{
        if(lan === "De"){
          return thirdFullBlogData[0].thirdBlogDe
        }else if(lan === "En"){
          return thirdFullBlogData[0].thirdBlogEn
        } else if(lan === "Ar"){
          return thirdFullBlogData[0].thirdBlogAr
        } else {
          return thirdFullBlogData[0].thirdBlogDe
        }
      })

    const [blogData, setBlogData] = useState(() => {
        if(lan === "De"){
           return blogDataDe
        } else if(lan === "En"){
           return blogDataEn
        } else if(lan === "Ar"){
           return blogDataAr
        } else {
            return blogDataDe
        }
    })

    const changeLanguage = (e) => {

        let value = e.target.value;

        // set the choosen language into localStorage
        localStorage.setItem("lan", value)
        
        // set the choosing Language from dataStore
         if(value === "De"){
            setData(webDataSourceDE)
            setTeamData(TeamDataDe)
            setBlogData(blogDataDe)
            setFirstBlogData(firstFullBlogData[0].firstBlogDe)
            setSecondBlogData(secondFullBlogData[0].secondBlogDe)
            setThirdBlogData(thirdFullBlogData[0].thirdBlogDe)
        } else if(value === "En"){
            setData(webDataSourceEN)
            setTeamData(TeamDataEn)
            setBlogData(blogDataEn)
            setFirstBlogData(firstFullBlogData[0].firstBlogEn)
            setSecondBlogData(secondFullBlogData[0].secondBlogEn)
            setThirdBlogData(thirdFullBlogData[0].thirdBlogEn)
        } else if(value === "Ar"){
            setData(webDataSourceAR)
            setTeamData(TeamDataAr)
            setBlogData(blogDataAr)
            setFirstBlogData(firstFullBlogData[0].firstBlogAr)
            setSecondBlogData(secondFullBlogData[0].secondBlogAr)
            setThirdBlogData(thirdFullBlogData[0].thirdBlogAr)
        } else {
            setData(webDataSourceDE)
            setTeamData(TeamDataDe)
            setBlogData(blogDataDe)
            setFirstBlogData(firstFullBlogData[0].firstBlogDe)
            setSecondBlogData(secondFullBlogData[0].secondBlogDe)
            setThirdBlogData(thirdFullBlogData[0].thirdBlogDe)
        }

    }

    useEffect(() => {

    }, [data, teamData, blogData])

    return (
        <DataStore.Provider value={{ data, changeLanguage, lan, teamData, blogData, firstBlogData, secondBlogData, thirdBlogData }}>
            {children}
        </DataStore.Provider>
    )
}

