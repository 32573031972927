import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataStore } from "../Store/Context";
import TypeAnimation from 'react-type-animation';
import 'aos/dist/aos.css';
import AOS from 'aos';
import './style/HomePage.style.css';

function HomePage() {

    const { data } = useContext(DataStore);

    const { title1, title2, title3, text1, text2, text3, text4, list1, list2, list3, list4, ownerImage,
        openTimeTitle1, telephone, openTime, hotLineTitle, hotLineNumber, hotLineDescription, address, plz, email, teamImage } = data[0].homePageData[0].introduction[0];

    const { servicesTitle, serviceName1, serviceDescription1, serviceName2, serviceDescription2, serviceName3, serviceDescription3, serviceName4, serviceDescription4,
        serviceName5, serviceDescription5 } = data[0].homePageData[0].ourServices[0];

    const { wisdom, wisdomAuthor, offerText1, offerText2, offerButton } = data[0].homePageData[0];

    useEffect(() => {
        AOS.init({ duration: 3000 });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, []);

    return (
        <div className="container">

         {/* page Title */}
         <div className="row mt-5 text-center">
         <h1 className=" fw-bolder title-st">{title1}</h1>
                        {/* animation title */}
                        <TypeAnimation
                            cursor={false}
                            sequence={[`${title2}`, 1000]}
                            wrapper="h3"
                        />
     </div>

            {/* photo and description */}
            <div className="row px-3">

                {/* contact information */}
                <div className="col-lg-4 mt-5">
                    <div className="border mb-0">
                        <img className="card-img-top mb-4" src={ownerImage} alt="hero" />


                        <div className="text-center">
                            <ul className="list-group-flush px-0 border-bottom">
                                <h5 className="list-group-item card-text title-st">{openTimeTitle1}</h5>
                                <li style={{ direction: "ltr", listStyle: "none" }} className="title-st3"><a className="text-decoration-none" href={`tel:${telephone}`}>{telephone}</a></li>
                                <li className="list-group-item">{openTime}</li>
                            </ul>
                            <ul className="list-group-flush px-0">
                                <li style={{ listStyle: "none" }} className="title-st">{hotLineTitle}</li>
                                <li style={{ direction: "ltr", listStyle: "none" }} className="title-st3"><a className="text-decoration-none" href={`tel:${hotLineNumber}`}>{hotLineNumber}</a></li>
                                <li className="list-group-item border-bottom title-st">{hotLineDescription}</li>
                            </ul>
                            <ul className="list-group-flush px-0">
                                <li style={{ listStyle: "none" }}>{address} <p className="card-text">{plz}</p></li>
                                <li className="list-group-item title-st3"><a className="text-decoration-none" href={`mailto:${email}`}>{email}</a></li>
                            </ul>

                        </div>
                    </div>
                </div>

                {/* introduction */}
                <div className="col-lg-8">
                    <div className="row">
                        <p className="pt-5 title-st">{title3}</p>
                        <ul className="px-5">
                            <li><p>{list1}</p></li>
                            <li><p>{list2}</p></li>
                            <li><p>{list3}</p></li>
                            <li><p>{list4}</p></li>
                        </ul>
                        <div>
                            <p className="pt-5">{text1}</p>
                            <p className="pt-3">{text2}</p>
                            <p className="pt-3">{text3}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* title section*/}
            <div className="row text-center my-5">
                <h5>{text4}</h5>
            </div>

            {/* team image */}
            <div className="row">
           {/* cancelled because of copyRight the image
          <img className="img-fluid my-5" data-aos="fade-up" src={teamImage} alt="" /> */}
            </div>

            {/* service section */}
            <div className="row">
                <div className="row" data-aos="fade-up"><h2 className="text-center my-5 fw-bolder title-st">{servicesTitle}</h2></div>

                <div className="col-lg-4 p-3 hover-st" data-aos="fade-up"><Link to="/translate" className="text-decoration-none">
                    <h5 className="card-title fw-bold title-st">{serviceName1}</h5>
                    <p className="card-text text-st">{serviceDescription1}</p>
                </Link></div>

                <div className="col-lg-4 p-3 hover-st" data-aos="fade-up"><Link to="/interpreting" className="text-decoration-none">
                    <h5 className="card-title fw-bold title-st">{serviceName2}</h5>
                    <p className="card-text text-st">{serviceDescription2}</p>
                </Link></div>

                <div className="col-lg-4 p-3 hover-st" data-aos="fade-up"><Link to="/privatelesson" className="text-decoration-none">
                    <h5 className="card-title fw-bold title-st">{serviceName3}</h5>
                    <p className="card-text text-st">{serviceDescription3}</p>
                </Link></div>

                <div className="col-lg-4 p-3 hover-st" data-aos="fade-up"><Link to="/acquisitionofdocument" className="text-decoration-none">
                    <h5 className="card-title fw-bold title-st">{serviceName5}</h5>
                    <p className="card-text text-st">{serviceDescription5}</p>
                </Link></div>

                <div className="col-lg-4 p-3 hover-st" data-aos="fade-up"><Link to="/familyreunion" className="text-decoration-none">
                    <h5 className="card-title fw-bold title-st">{serviceName4}</h5>
                    <p className="card-text text-st">{serviceDescription4}</p>
                </Link></div>

                <div className="col-lg-4"></div>
            </div>

            {/* wisdom */}
            <div className="row text-center mt-5 mb-5" >
                <h1>{wisdom}</h1>
                <p>{wisdomAuthor}</p>
            </div>
            <hr />
            <div className="row text-center mt-5 mb-5">
                <h5>{offerText1}</h5>
                <h5>{offerText2}</h5>
            </div>
            <div className="row text-center mt-5 mb-5 d-flex justify-content-center">
                <div className="col"><Link to="/requestforquote" className="btn btn-primary btn-lg">{offerButton}</Link></div>
            </div>

        </div>
    )
}

export default HomePage

