import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataStore } from "../Store/Context";

function VisionPage() {

    const { data } = useContext(DataStore);

    const { title, text1, text2, text3, text6, title1, title1Description1, title1Description2, title1Description3, text4, text5 } = data[0].visionPageData[0];
    const { offerText1, offerText2, offerButton } = data[0].homePageData[0];

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
      }, []);
      
    return (
        <div className="container px-3">
            {/* page Title */}
            <div className="row mt-5 mb-5 text-center">
                <h3>{title}</h3>
            </div>

            {/* first section */}
            <div className="row mb-4">
                <p>{text1}</p>
                <p>{text2}</p>
                <p>{text3}</p>
                <p>{text6}</p>
            </div>

            {/* second section */}
            <div className="row mb-4">
                <h5>{title1}</h5>
                <ol className="px-5">
                    <li>{title1Description1}</li>
                    <li>{title1Description2}</li>
                    <li>{title1Description3}</li>
                </ol>
            </div>

            {/* third section */}
            <div className="row mb-4">
                <p>{text4}</p>
                <p>{text5}</p>
            </div>

            {/* wisdom */}
            <div className="row text-center mb-5 pt-5">
                <h5>{offerText1}</h5>
                <h5>{offerText2}</h5>
            </div>
            <div className="row text-center mt-5 mb-5 d-flex justify-content-center">
                <div className="col"><Link to="/requestforquote" className="btn btn-primary btn-lg">{offerButton}</Link></div>
            </div>
        </div>
    )
}

export default VisionPage
